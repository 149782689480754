import React from "react";
import { FaBook } from "react-icons/fa";
import "./../style/democlass.css";
import { Link } from "react-router-dom";
import classroom from "./../image/classroom.jpg";

const DemoClasses = () => {
  return (
    <section className="democlasses">
      <div className="headingofdemo">
        <h1>
          <FaBook /> CSIR NET Life Sciences Online / Classroom Coaching |{" "}
          <Link to="/">Demo Classes</Link>
        </h1>
      </div>
      <div className="headingpara">
        <p>
          <span>CSIR NET Life Sciences Online Live Coaching</span> | CSIR NET
          Life Sciences Classroom Coaching |{" "}
          <span>
            IIT-JAM / M. Sc. Entrance / GATE Biotechnology Online Live Coaching
          </span>{" "}
          | Recorded Video Lecture (PEN Drive / Google Drive) for CSIR NET Life
          Sciences| Comprehensive Study Materials (Books) |{" "}
          <span>
            Online Test Series | Crack NET -JRF / GATE / JAM 2022 & 2022
            Examinations.
          </span>{" "}
          | Join New Batches for Online Classes or Order for Best Study Material
          of India
        </p>
      </div>
      <div className="contentofpara">
        <div className="gatelifesci">
          <img data-aos="fade-right" src="https://resource.logitech.com/w_1001,c_limit,q_auto,f_auto,dpr_1.0/d_transparent.gif/content/dam/logitech/en/education/education-center/article/classroom-now/article-classroom-now.jpg?v=1" alt="" />
          <div className="gatelifesciinner">
            <p>
            CSIR-UGC (NET) Exam for Award of Junior Research Fellowship and Eligibility for Lecturership shall
be a Single Paper Test having Multiple Choice Questions (MCQs). The question paper is divided in three
parts : Part 'A'  (General Aptitude), Part 'B' (straight forward questions) and Part 'C' (analytical
questions). There will be negative marking @25% for each wrong answer.
            </p>
            <p>
            The CSIR-UGC (NET) Exam for the Junior Research Fellowship and eligibility for Lecturership is a Single Paper Test with Multiple Choice Questions (MCQs).
            </p>
          </div>
        </div>
        <div className="gatelifesci1">
          <img data-aos="fade-left" src="https://images.ctfassets.net/5i1m3im8l2b5/221lZIAVNjruQwaQFueTVl/8027e43e1da391c20919be79c3ed815d/splashtop-classroom-hero.jpg?w=450&h=300&fl=progressive&q=75&fm=jpg&fit=fill" alt="" />
          <div className="gatelifesciinner1">
            <p>
            Come and join our CSIR NET Life Sciences exam preparation program, where we offer unparalleled coaching classes tailored for success. Our focus is on providing comprehensive guidance to students aiming to crack the CSIR NET Life Sciences Examination and secure Junior Research Fellowship (JRF) for Ph.D. courses. We understand the diverse needs of students across the nation, and thus, we offer a range of courses to cater to every learning style
            </p>
            <p>
            Regular Classroom Coaching:

Immerse yourself in a structured learning environment with our regular classroom coaching sessions. Benefit from interactive sessions and direct interaction with experienced instructors.
Weekend Classroom Coaching:

For those with busy schedules during the week, our weekend classroom coaching provides a convenient option to receive quality instruction while balancing other commitments.
Online Live Coaching:

Join our virtual classrooms from the comfort of your home. Our online live coaching brings the expertise of our instructors directly to you, ensuring flexibility and accessibility.
Video Lectures:

Access a treasure trove of educational content through our video lectures. Learn at your own pace, rewind and review challenging concepts whenever needed.
Postal Courses:

If you prefer the traditional route, our postal courses deliver study material right to your doorstep. Receive comprehensive materials to aid your preparation." "
            </p>
          </div>
        </div>
        <div className="gatelifesci">
          <img data-aos="fade-right" src="https://pbacademic.com/wp-content/uploads/2020/04/DSC3458-1024x683.jpg" alt="" />
          <div className="gatelifesciinner">
            <p>
              We also conduct preparation of M.Sc Entrance Examination, IIT-JAM
              for the subject Life Science / Biological Sciences, Biotechnology.
              We provide Regular Classroom Coaching to Prepare for IIT-JAM &
              Other M.Sc Entrance Examinations. We have a very good success
              ratio for all of the examinations.
            </p>
            <p>
              Preparation for GATE with Life Science, Biotechnology, Food
              Technology as Subjects are also done through Regular Classroom
              Coaching & Correspondence Courses. We are Ranked No-1 in GATE Exam
              Coaching and have a very good success ratio from the last 10
              years. We provide excellent teaching with special class notes to
              every Student of GATE and conduct weekly Tests to evaluate
              performance.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoClasses;
