// import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import contactusimage from "../image/contactus.png";
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
import React, { useState } from "react";
import firebase from "firebase/compat/app"; // Import the compat version
import "firebase/compat/firestore"; // Import the compat version of Firestore
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// import contactusimage from "../images/8771935-removebg-preview.png";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    mscin: "",
    programs: [],
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      programs: checked
        ? [...prevData.programs, id]
        : prevData.programs.filter((program) => program !== id),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show the loader
    setLoading(true);

    try {
      // await axios.post("http://localhost:5500/contactusform", formData);
      await axios.post(
        "https://ona-backend.onrender.com/contactusform",
        formData
      );
      setSubmitted(true);
      setFormData({
        name: "",
        email: "",
        contact: "",
        mscin: "",
        programs: [],
      });
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    } finally {
      // Hide the loader after the form submission is complete
      setLoading(false);
    }
  };

  return (
    <>
      <Container style={{ padding: "0", backgroundColor: "white" }}>
        <Row
          className="mx-auto justify-content-center"
          style={{ maxWidth: "86%", marginTop: "40px" }}
        >
          <Col
            xs={12}
            md={6}
            className="pl-2"
            style={{ backgroundColor: "#eee" }}
          >
            <div className="text-center mb-4 mt-5">
              <h2 style={{ fontWeight: "bold" }}>Get Connected Locally</h2>
            </div>
            <Form onSubmit={handleSubmit} className="pb-4">
              {submitted && (
                <Alert variant="success" className="mt-3">
                  Your form has been submitted successfully!
                </Alert>
              )}
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Your Name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter Your Email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Your Contact No."
                  id="contact"
                  value={formData.contact}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                  required
                  type="text"
                  placeholder="MSc In"
                  id="mscin"
                  value={formData.mscin}
                  onChange={handleChange}
                />
              </Form.Group>

              {/* <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                required
                  type="text"
                  placeholder="Your Preferred Location ?"
                  id="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </Form.Group> */}

              {/* <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                required
                  type="text"
                  placeholder="Your preferred languages ?"
                  id="preferredlanguages"
                  value={formData.preferredlanguages}
                  onChange={handleChange}
                />
              </Form.Group> */}

              <Form.Group className="mb-3">
                <Form.Label>How to you Know about our Institute *</Form.Label>
                <Row className="ml-3">
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Google"
                      id="Google"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Facebook"
                      id="Facebook"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Friends"
                      id="Friends"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Check
                      type="checkbox"
                      label="Teachers/Seniors"
                      id="Teachers/Seniors"
                      onChange={handleCheckboxChange}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Button
                style={{
                  backgroundColor: "#fd854a",
                  border: "none",
                  width: "100%",
                }}
                type="submit"
              >
                Submit
              </Button>
              {loading && <div className="loader"></div>}
            </Form>
          </Col>

          <Col xs={12} md={6} className=" d-flex  justify-content-center">
            <div className="text-center mb-4 ">
              <img
                data-aos="flip-left"
                src={contactusimage}
                alt="Description"
                style={{ maxWidth: "100%", maxHeight: "100%", width: "auto" }}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3740.5814035434482!2d85.82704667851257!3d20.35890440036891!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909018b70a799%3A0x1706b3ff35917fe6!2sOdisha%20NET%20Academy-ONA!5e0!3m2!1sen!2sin!4v1704626614866!5m2!1sen!2sin"
        width="100%"
        height="550"
        allowFullScreen=""
        loading="lazy"
        style={{ marginTop: "30px" }}
        referrerPolicy="no-referrer-when-downgrade"
        className="mobile-margin-top"
      ></iframe>
    </>
  );
};

export default ContactUs;
