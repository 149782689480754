import React from "react";
import "./../../style/director.css";
import sci from "./../../image/sci.png"
const AboutIns = () => {
  return (
    <section>
      <div className="institutehead">
        <h1>About Our Institute (ONA)</h1>
      </div>
      <div className="institutepara">
        <div className="gatelifesci">
          <img data-aos="flip-left" src="https://www.gteducation.edu.in/wp-content/uploads/2020/10/college-campus1..jpg" alt="" />
          <div className="gatelifesciinner">
            <p>
             <span style={{color:"red", fontSize:"25px", fontWeight: "bold"}}>Odisha NET Academy (ONA) </span> is the pioneer CSIR NET Academy (For Life
              Sciences) in Bhubaneswar, Odisha. It was established in 2011
              August 8th. Although the aim of our classes is to prepare the
              students for JRF-NET exam, we also provide additional useful
              information to reinforce their foundation so that they are
              equipped to face any interview or other Ph.D. entrance Exams. Thus
              the coaching is also beneficial for GATE, ICMR, DBT and various
              other entrance examinations.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutIns;
