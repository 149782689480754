import React, { useEffect, useState } from "react";
import { imageDb } from "../firebaseConfig";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import "./../style/HomeScreenSlider.css";
import Home1 from "./../image/home1.jpg";
import Home2 from "./../image/home2.jpg";
import Home3 from "./../image/home3.jpg";

// import { baseUrl } from "./config";

const HomeScreenSlider = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const ImageArray = [Home1, Home2, Home3];
  useEffect(() => {
    listAll(ref(imageDb, "Homeslider")).then((imgs) => {
      console.log(imgs);
      imgs.items.forEach((val) => {
        getDownloadURL(val).then((url) => {
          setImageUrls((prevUrls) => {
            if (!prevUrls.includes(url)) {
              return [...prevUrls, url];
            }
            return prevUrls;
          });
        });
      });
    });
  }, []);
  // const settings = {
  //   // dots: true,
  //   fade: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  return (
    <div>
      <div
        id="myCarousel"
        class="carousel slide"
        data-ride="carousel"
        data-interval="2000"
      >
        <div className="carousel-inner">
          {imageUrls
            ? imageUrls.map((imageUrl, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    src={imageUrl}
                    className="d-block w-100a"
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              ))
            : ImageArray.map((imageUrl, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    src={imageUrl}
                    className="d-block w-100a"
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HomeScreenSlider;
