import React, { useEffect } from "react";
import DirectorImg from "./../../image/director.jpg";
import "./../../style/director.css";
import AOS from 'aos'
const Director = () => {
  useEffect(() => {
    AOS.refresh(); // Refresh AOS when the component mounts
  }, []);
  return (
    <section>
      <div className="directorhead">
        <h1>Director Desk</h1>
      </div>
      <div className="directordesk">
        <div className="directormssg">
          <p>My Dear Students,</p>
          <p>
            {" "}
            Most welcome to Odisha NET Academy-ONA, Bhubaneswar. A lot of
            students have cleared CSIR NET Exam, GATE, DBT, ICMR and ARS NET
            from ONA. We believe in <b>fundamental</b> studies. Dear students,
            focus on three things to get success in any field:{" "}
            <b>Hard work, Regularity and Punctuality.</b>
            ONA is committed to help you to achieve your DREAM. ONA is the right
            place to improve your fundamental and analytical knowledge. You will
            be guided by most experienced faculty who have cleared CSIR NET and
            GATE many times. You can clear your doubts in the classroom itself.
            We are conducting weekly tests on Sundays for the taught portion
            along with the surprise tests in between. Also special guest
            lectures are organized from time to time, which are delivered by
            eminent academicians and professors from prestigious universities
            and institutes. Wishing you a world of success with us.
          </p>
          <p>
            Madhusmita Pradhan <br /> Director <br /> Odisha NET Academy-ONA
          </p>
        </div>
        <img style={{width:"20rem"}} data-aos="fade-up-left" src={DirectorImg} alt="" />
      </div>
    </section>
  );
};

export default Director;
