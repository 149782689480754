import React from "react";
import Img1 from "./../../image/classroom.jpg";
// import Pdf from "./../../image/syllabus.pdf"
import "./../../style/csir.css";

const courseData = [
  {
    name: "Regular Classes (including Registration Fee+ Study Material + All India Test Series Offline + Online)",
    code: "(GBL-1)",
    fee: "Rs. 42,000/-",
  },
  { name: "Online Coaching Classes", code: "", fee: "Rs. 28,000/-" },
  {
    name: "Weekend Classes (Saturday & Sunday)",
    code: "(GBL-2)",
    fee: "Rs. 35,000/-",
  },
  {
    name: "Correspondence Material + (offline + online Test Series) + 2 Books",
    code: "(GBL-3)",
    fee: "Rs. 10,000/-",
  },
  {
    name: "Correspondence Material (13 Units + General Aptitude) + 2 Books",
    code: "(GBL-4)",
    fee: "Rs. 8,000/-",
  },
  {
    name: "All India Test Series Offline",
    code: "(GBL-5)",
    fee: "Rs. 4,000/-",
  },
  { name: "All India Test Series Online", code: "(GBL-6)", fee: "Rs. 2,000/-" },
  {
    name: "Topic Wise Solved Model Papers with Explanations (Book)",
    code: "(GBL-7)",
    fee: "Rs. 395/-",
  },
  {
    name: "CSIR-JRF-NET Previous Years Solved Question Papers (Book)",
    code: "(GBL-8)",
    fee: "Rs. 501/-",
  },
  { name: "Regular Guarantee Batch", code: "(GBL-9)", fee: "Rs. 50,000/-" },
];

const gateBiotechData = [
  {
    name: "Regular Classroom Coaching for GATE NET: Offline",
    code: "(GBL-10)",
    fee: "Rs. 37,000/-",
  },
  {
    name: "Regular Classes for GATE + CSIR JRF NET Life Sciences (with compulsory Chemistry)",
    code: "OANGATE-3",
    fee: "Rs. 18,000/-",
  },
  {
    name: "GATE Biotech. Weekend Classes (Saturday & Sunday) (Six Months)",
    code: "(GBL-12)",
    fee: "Rs. 30,000/-",
  },
  {
    name: "GATE Biotech. Weekend Classes (Saturday & Sunday)  (1 year)",
    code: "(GBL-13)",
    fee: "Rs. 30,000/-",
  },
  {
    name: "GATE Crash Course Biotechnology",
    code: "(GBL-14)",
    fee: "Rs. 5,000/-",
  },
  {
    name: "Chemistry (GATE Life Sc. Compulsory Paper)",
    code: "(GBL-15)",
    fee: "Rs. 5,000/-",
  },
  {
    name: "IIT-JAM Biotechnology & JNU Entrance Exam.",
    code: "(GBL-16)",
    fee: "Rs. 31,000/-",
  },
  {
    name: "CSIR-JRF-NET & IIT-JAM Biotechnology & JNU Entrance Exam",
    code: "(GBL-17)",
    fee: "Rs. 31,000/-",
  },
];

const CsirNetClassroom = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir"><h1>CSIR NET LIFE SCIENCES CLASSROOM COACHING</h1></div>
      </div>
      
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <h5>
            Odisha NET Academy (ONA)  has made a comprehensive 5 months full-time
            classroom teaching programmes for the Monsoon Session 10th July /
            25th July / 10th Aug Winter Session 10th Jan / 25th Jan / 25th Feb
            batch in every year, the 10th July batch will appear for the
            December Examination and 10th January batch will appear for the June
            Examination.
          </h5>
          <p>
            The teaching programme is supported with reference study material,
            tests, and practice papers. This is uniquely designed under the time
            management method to provide maximum information in the shortest
            possible time.
          </p>
          <p>
            <b>
              REGULAR/Online & CORRESPONDENCE FEE DETAIL <br />
              Course Fee for CSIR-UGC (JRF-NET/GATE) 5 Months Programme
            </b>
          </p>
        </div>
      </div>
      {/* <div className="course-table-container">
        <table data-aos="fade-left" className="styled-table">
          <thead>
            <tr>
              <th>Courses Offered</th>
              <th>GBA Code</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            {courseData.map((course, index) => (
              <tr key={index}>
                <td>{course.name}</td>
                <td>{course.code}</td>
                <td>{course.fee}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      {/* <h5 className="gate">GATE - BIOTECHNOLOGY Exam (7 Months Programme)</h5>
      <div className="gate-biotech-table-container">
        <table data-aos="fade-right" className="styled-table">
          <thead>
            <tr>
              <th>Courses Offered</th>
              <th>GBA Code</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            {gateBiotechData.map((course, index) => (
              <tr key={index}>
                <td>{course.name}</td>
                <td>{course.code}</td>
                <td>{course.fee}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      <div className="csirnetpara1">
        <p>
          <b>REGULAR TEACHING PROGRAMME</b> <br />
          <br /> Odisha NET Academy (ONA)  has made a comprehensive classroom study
          programme for purpose of admission in different Ph.D. courses from JNU
          / DU / IIT / BHU / TIFR / IISc / ICMR / AIIMS etc. by cracking the
          CSIR UGC JRF NET Life Sciences exam. The main feature of this course
          is that students with subject understanding at all levels, from basic
          to advance, can get benefit from the course JRF NET Life Science
          Coaching. The Classes are taken by Eminent Professors, Top Rankers,
          and educationists with long-time teaching and research experience in
          their respective fields. The classrooms are equipped with most modern
          teaching gadgets including LCD color projectors which make a highly
          visible presentation of the complex topics so that students can
          understand them with the help of colorful diagrams/animations and
          systematic writeup. We provide study material and all India test
          series for regular students.
        </p>
        <p>
          We also invite visiting faculty from different universities to take up
          important topics. Study material with all relevant details is provided
          to all classroom courses students during the sessions. Regular
          assignments are provided to the students for self-practice. Proper
          emphasis is given to creating, both objective and conceptual
          question-solving abilities of the students in the assignments. This
          course basically aims at utilizing the classroom time in the best
          possible way to crack this NET Life Science exam.
        </p>
        <p>
          Classes are conducted for Three to Five hours daily, Five to Six days
          a week for around five months. We also give great emphasis to
          discussion classes and doubt clearance classes. The assessment of
          students performance is done on regular basis via unit tests based on
          examination patterns along with relevant discussions in the classes.
          After completion, of the course, test series are conducted for one
          week before the examination. Test series are conducted separately for
          CSIR-UGC-NET/JRF & GATE Examinations. www.gyanbinduacademy.com
        </p>
        <p>
        Like other exams, in order to qualify JRF-NET requires a bit of good management of time and effort along with proper guidance. First the emphasis is on topics for logical problems (part C) and secondly on guidance in solving objective type problems (part A and B) quickly. In other words, in order to attempt 25 part C questions from the given approximately 75 questions, it is not important to cover the entire syllabus; rather the knowledge of the paper's pattern is important i.e. from which part the probability of getting many questions is higher. Our team is keeping track of the previous year's paper (JRF and GATE, both) and based on it we select some important topics for the preparation. Also for objective type questions we provide a different approach to solve the problem quickly and correctly.
        </p>
      </div>
    </section>
  );
};

export default CsirNetClassroom;
