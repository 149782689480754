import React, { useState } from "react";
import "./../style/getemailupdate.css";
import headingImage from "./../image/classroom.jpg";

import firebase from "firebase/compat/app"; // Import the compat version
import "firebase/compat/firestore"; // Import the compat version of Firestore
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// import contactusimage from "../images/8771935-removebg-preview.png";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

export default function GetEmailUpdate() {
  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    mscin: "",
    programs: [],
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      programs: checked
        ? [...prevData.programs, id]
        : prevData.programs.filter((program) => program !== id),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // await axios.post("http://localhost:5500/getemailupdate", formData);
      await axios.post(
        "https://ona-backend.onrender.com/getemailupdate",
        formData
      );
      setSubmitted(true);
      setFormData({
        name: "",
        email: "",
        contact: "",
        mscin: "",
        programs: [],
      });
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="getemailupdate-container">
      <div className="getemailupdaterow">
        <div className="getemailupdatecolumn d-flex justify-content-center align-items-center">
          <img src={headingImage} alt="Description" className="frontimage" />
        </div>
        <div
          className="getemailupdatecolumn"
          style={{ backgroundColor: "#eee" }}
        >
          <h1>Get Email Updates</h1>
          <p>
            Hare Krishna, we are glad you would like to connect to our email
            updates. Fill in brief information about you and your preferred
            email topic. You are welcome to select as many as you like.
          </p>

          <Form onSubmit={handleSubmit} className="pb-4">
            {submitted && (
              <Alert variant="success" className="mt-3">
                Your form has been submitted successfully!
              </Alert>
            )}
            <Form.Group controlId="formName" className="mb-3">
              <Form.Control
                required
                type="text"
                placeholder="Enter Your Name"
                id="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control
                required
                type="email"
                placeholder="Enter Your Email"
                id="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formContact" className="mb-3">
              <Form.Control
                required
                type="number"
                placeholder="Enter Your Contact No."
                id="contact"
                value={formData.contact}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formContact" className="mb-3">
              <Form.Control
                required
                type="text"
                placeholder="MSc In"
                id="mscin"
                value={formData.mscin}
                onChange={handleChange}
              />
            </Form.Group>

            {/* <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                required
                  type="text"
                  placeholder="Your Preferred Location ?"
                  id="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </Form.Group> */}

            {/* <Form.Group controlId="formContact" className="mb-3">
                <Form.Control
                required
                  type="text"
                  placeholder="Your preferred languages ?"
                  id="preferredlanguages"
                  value={formData.preferredlanguages}
                  onChange={handleChange}
                />
              </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>How to you Know about our Institute *</Form.Label>
              <Row className="ml-3">
                <Col xs={12} sm={6}>
                  <Form.Check
                    type="checkbox"
                    label="Google"
                    id="Google"
                    onChange={handleCheckboxChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Check
                    type="checkbox"
                    label="Facebook"
                    id="Facebook"
                    onChange={handleCheckboxChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Check
                    type="checkbox"
                    label="Friends"
                    id="Friends"
                    onChange={handleCheckboxChange}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Check
                    type="checkbox"
                    label="Teachers/Seniors"
                    id="Teachers/Seniors"
                    onChange={handleCheckboxChange}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Button
              style={{
                backgroundColor: "#fd854a",
                border: "none",
                width: "100%",
              }}
              type="submit"
            >
              Submit
            </Button>
            {loading && <div className="loader"></div>}
          </Form>
        </div>
      </div>
    </div>
  );
}
