import React from "react";
import Img1 from "./../../image/picturemessage.jpg";
import Img2 from "./../../image/classroom.jpg";
import "./../../style/csir.css";
const CsirNetCoach = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">
      <h1>CSIR NET LIFE SCIENCES COACHING</h1>

        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-left" src={Img1} alt="" />
        <div className="csirnetpara">
          <h5>REGULAR TEACHING PROGRAMME for CSIR NET Life Sciences</h5>
          <p>
          CSIR-UGC (NET) Exam for Award of Junior Research Fellowship and Eligibility for Lecturership shall
be a Single Paper Test having Multiple Choice Questions (MCQs). The question paper is divided in three
parts : Part 'A'  (General Aptitude), Part 'B' (straight forward questions) and Part 'C' (analytical
questions). There will be negative marking @25% for each wrong answer.
          </p>
        </div>
      </div>
      <div className="infor1">
        <img data-aos="fade-right" src="https://lh3.googleusercontent.com/proxy/rYYhdE2g6Bt6-3BH2P6mrryOoGM5pxP_4Y1Q0i564yoFRoQWofjGYaUzoTrWc7mWCQx58s0Pp7b-sfRQKlpvxqDk67t-HWvqxo1G2ZP8N_ac3qSNGwi-FQQaho0" alt="" />
        <div className="csirnetpara1">
          <p>
            <b>Odisha NET Academy (ONA)  Features:</b>
            <br />
            Own study material and an online test series for our student <br />
            Lectures covering the complete syllabus for CSIR NET according to a
            fixed schedule <br />
            Lectures conducted by some of the best CSIR NET faculty in the
            country. <br />
            Phone and chat-based interaction with faculty <br />
            facility to view last 7 days of classes video recording. <br />
            with 55 minutes of lecture followed by 5 minutes of break and then
            concluded with a 15 minute Q & A session. <br />
            The syllabus topics are covered in a cyclical manner ensuring all
            students cover the entire syllabus irrespective of when they join.{" "}
            <br />
            Regular Classes & weekend classes also as per the need of the
            student demand. student demand.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CsirNetCoach;
