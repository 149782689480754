// src/PrivateRoute.js

import React from "react";
import { Route, Navigate } from "react-router-dom";
import { auth } from "./../firebaseConfig.js";

const PrivateRoute = ({ element, ...rest }) => {
  const user = auth.currentUser;

  return (
    <Route
      {...rest}
      element={user ? element : <Navigate to="/login" replace />}
    />
  );
};

export default PrivateRoute;
