import React, { useState } from "react";
import Img1 from "../image/ONA_LOGO.jpg";
import "./../style/header.css";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { FaBookOpen } from "react-icons/fa";
import { PiExamFill } from "react-icons/pi";
import { FaBloggerB } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { RiGalleryLine } from "react-icons/ri";
import { HiCurrencyRupee } from "react-icons/hi2";
import { SiPhonepe } from "react-icons/si";
import { MdPayments } from "react-icons/md";

const Header = () => {
  const [isLogoVisible, setLogoVisible] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setLogoVisible(!isLogoVisible);
    setMenuOpen(!isMenuOpen);
    // Add any other logic you want to perform when the hamburger is clicked
  };
  const handleMenuItemClick = () => {
    setMenuOpen(false);
    setLogoVisible(!isLogoVisible);
    // Add any other logic you want to perform when a menu item is clicked
  };
  return (
    <>
      <div className="navbar11 settingmain1">
        <div className="navbardisplay">
          {isLogoVisible && (
            <img
              style={{ display: isLogoVisible ? "block" : "none" }}
              src={Img1}
              alt=""
            />
          )}
        </div>
        <nav>
          <label
            onClick={handleHamburgerClick}
            for="drop"
            class="toggle "
            style={{ fontSize: "32px" }}
          >
            &#8801;
          </label>
          <input type="checkbox" id="drop" />

          <ul style={{ display: isMenuOpen ? "block" : "none" }} class="menu">
            <li onClick={handleMenuItemClick}>
              <Link to="/">
                <FaHome /> Home
              </Link>
            </li>

            <li>
              {/* <!-- First Tier Drop Down --> */}
              <label for="drop-1" class="toggle">
                <IoMdPerson /> About
              </label>
              <Link to="/director-desk">
                <IoMdPerson />
                About
              </Link>
              <input type="checkbox" id="drop-1" />
              <ul className="addingImage">
                <div className="imaging1">
                  <li onClick={handleMenuItemClick}>
                    <Link to="/director-desk">
                      <FaBookOpen />
                      Director-Desk
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleMenuItemClick} to="/about-institute">
                      <FaBookOpen />
                      About Institute
                    </Link>
                  </li>
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    <label for="drop-20" class="toggle">
                      <FaBookOpen />
                      Online fee Payment
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      Online fee Payment
                    </Link>
                    <input type="checkbox" id="drop-20" />
                    <ul className="addingimg1">
                      <div className="imaging11">
                        <li onClick={handleMenuItemClick}>
                          <Link to="/">
                            <HiCurrencyRupee /> Cash payment
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/">
                            <SiPhonepe /> Phonpay payment
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/">
                            <MdPayments /> Online payment
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </div>
                {/* <img src={Img2} alt="" /> */}
              </ul>
            </li>
            <li>
              {/* <!-- First Tier Drop Down --> */}
              <label for="drop-2" class="toggle">
                <PiExamFill /> CSIR NET/GATE
              </label>
              <Link to="/">
                <PiExamFill />
                CSIR NET/GATE
              </Link>
              <input type="checkbox" id="drop-2" />
              <ul className="addingImage">
                <div className="imaging1">
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    <label for="drop-30" class="toggle">
                      <FaBookOpen />
                      CSIR-NET life science
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      CSIR-NET life science
                    </Link>
                    <input type="checkbox" id="drop-30" />
                    <ul className="addingimg1">
                      <div className="imaging11">
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-coaching">
                            <FaBookOpen /> CSIR-NET Life Science Coaching
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-syllabus">
                            <FaBookOpen /> CSIR-NET Life Science Syllabus
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-pattern">
                            <FaBookOpen /> CSIR-NET Life Science Pattern
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-classroom">
                            <FaBookOpen /> CSIR-NET Life Sci. Classroom Coaching
                          </Link>
                        </li>
                        {/* <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-corresponding">
                            <FaBookOpen /> CSIR-NET Life Science Correspondence
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-science">
                            <FaBookOpen /> Live Online CSIR-NET Life Science
                          </Link>
                        </li> */}
                        {/* <li>
                    <Link to="/">
                      <FaBookOpen />
                      NET JRF Life science Lecture
                    </Link>
                  </li> */}
                      </div>
                    </ul>
                  </li>
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    <label for="drop-200" class="toggle">
                      <FaBookOpen /> GATE
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      GATE
                    </Link>
                    <input type="checkbox" id="drop-200" />
                    <ul className="addingimg1">
                      <div className="imaging11">
                        <li>
                          {/* <!-- First Tier Drop Down --> */}
                          <label for="drop-57" class="toggle">
                            <FaBookOpen />
                            Gate (Biotechnology)
                          </label>
                          <Link to="/">
                            <FaBookOpen />
                            Gate (Biotechnology)
                          </Link>
                          <input type="checkbox" id="drop-57" />
                          <ul className="ulline">
                            <div className="imaging1111">
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-syllabus">
                                  <FaBookOpen /> Gate Biotechnology Syllabus
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-pattern">
                                  <FaBookOpen /> Gate Biotechnology Exam Pattern
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-classroom">
                                  <FaBookOpen /> Gate Biotechnology CLassroom
                                  Coaching
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-correspondence">
                                  <FaBookOpen /> Gate Biotechnology
                                  Correspondence
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-coaching">
                                  <FaBookOpen /> Online Gate Biotechnology
                                  Coaching
                                </Link>
                              </li>
                            </div>
                          </ul>
                        </li>
                        <li>
                          {/* <!-- First Tier Drop Down --> */}
                          <label for="drop-56" class="toggle">
                            <FaBookOpen />
                            Gate (Life Science)
                          </label>
                          <Link to="/">
                            <FaBookOpen />
                            Gate (Life Science)
                          </Link>
                          <input type="checkbox" id="drop-56" />
                          <ul className="ulline">
                            <div className="imaging1111">
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-syllabus">
                                  <FaBookOpen /> Gate Life Science Syllabus
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-pattern">
                                  <FaBookOpen /> Gate Life Science Exam Pattern
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-classroom">
                                  <FaBookOpen /> Gate Life Science CLassroom
                                  Coaching
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-correspondence">
                                  <FaBookOpen /> Gate Life Science
                                  Correspondence
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-coaching">
                                  <FaBookOpen /> Online Gate Life Science
                                  Coaching
                                </Link>
                              </li>
                            </div>
                          </ul>
                        </li>
                      </div>
                    </ul>
                  </li>
                </div>
              </ul>
            </li>
            <li onClick={handleMenuItemClick}>
              <Link to="/blog">
                <FaBloggerB />
                Blog
              </Link>
            </li>
            <li onClick={handleMenuItemClick}>
              <Link to="/">
                <PiExamFill />
                Online Test Series
              </Link>
            </li>
            <li onClick={handleMenuItemClick}>
              <Link to="/gallery">
                {" "}
                <RiGalleryLine />
                Gallery
              </Link>
            </li>
            <li>
              {/* <!-- First Tier Drop Down --> */}
              <label for="drop-5" class="toggle">
                <FaPhoneAlt />
                Contact Us
              </label>
              <Link to="/get-connected-locally">
                <FaPhoneAlt />
                Contact Us
              </Link>
              <input type="checkbox" id="drop-5" />
              <ul className="ulline">
                <div className="imaging111">
                  <li onClick={handleMenuItemClick}>
                    <Link to="/get-connected-locally">
                      <FaPhoneAlt />
                      Get Connected
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/connect-online">
                      <FaPhoneAlt />
                      Connected online
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/how-to-reach">
                      <FaPhoneAlt />
                      How To Reach
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/calendar">
                      <FaPhoneAlt />
                      Calender
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/get-email-update">
                      <FaPhoneAlt />
                      Get Email Update
                    </Link>
                  </li>
                </div>
              </ul>
            </li>

            {/* <li><Link to="#">About</a></li> */}
          </ul>
        </nav>
      </div>
      <div className="navbar11 settingMain">
        <div className="navbardisplay">
          <img src={Img1} alt="" />
        </div>
        <nav>
          <label
            onClick={handleHamburgerClick}
            for="drop"
            class="toggle "
            style={{ fontSize: "32px" }}
          >
            &#8801;
          </label>
          <input type="checkbox" id="drop" />

          <ul class="menu">
            <li onClick={handleMenuItemClick}>
              <Link to="/">
                <FaHome /> Home
              </Link>
            </li>

            <li>
              {/* <!-- First Tier Drop Down --> */}
              <label for="drop-1" class="toggle">
                <IoMdPerson /> About
              </label>
              <Link to="/director-desk">
                <IoMdPerson />
                About
              </Link>
              <input type="checkbox" id="drop-1" />
              <ul className="addingImage">
                <div className="imaging1">
                  <li onClick={handleMenuItemClick}>
                    <Link to="/director-desk">
                      <FaBookOpen />
                      Director-Desk
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleMenuItemClick} to="/about-institute">
                      <FaBookOpen />
                      About Institute
                    </Link>
                  </li>
                  {/* <li onClick={handleMenuItemClick}>
                    <Link to="/">
                      <FaBookOpen />
                      Online fee Payment
                    </Link>
                  </li> */}
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    <label for="drop-20" class="toggle">
                      <FaBookOpen />
                      Online fee Payment
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      Online fee Payment
                    </Link>
                    <input type="checkbox" id="drop-20" />
                    <ul className="addingimg1">
                      <div className="imaging11">
                        <li onClick={handleMenuItemClick}>
                          <Link to="">
                            <HiCurrencyRupee /> Cash payment
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="">
                            <SiPhonepe /> Phonpay payment
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="">
                            <MdPayments /> Online payment
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </div>
                {/* <img src={Img2} alt="" /> */}
              </ul>
            </li>
            <li>
              {/* <!-- First Tier Drop Down --> */}
              <label for="drop-2" class="toggle">
                <PiExamFill /> CSIR NET/GATE
              </label>
              <Link to="/">
                <PiExamFill />
                CSIR NET/GATE
              </Link>
              <input type="checkbox" id="drop-2" />
              <ul className="addingImage">
                <div className="imaging1">
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    <label for="drop-30" class="toggle">
                      <FaBookOpen />
                      CSIR-NET life science
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      CSIR-NET life science
                    </Link>
                    <input type="checkbox" id="drop-30" />
                    <ul className="addingimg1">
                      <div className="imaging11">
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-coaching">
                            <FaBookOpen /> CSIR-NET Life Science Coaching
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-syllabus">
                            <FaBookOpen /> CSIR-NET Life Science Syllabus
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-pattern">
                            <FaBookOpen /> CSIR-NET Life Science Pattern
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-classroom">
                            <FaBookOpen /> CSIR-NET Life Sci. Classroom Coaching
                          </Link>
                        </li>
                        {/* <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-corresponding">
                            <FaBookOpen /> CSIR-NET Life Science Correspondence
                          </Link>
                        </li>
                        <li onClick={handleMenuItemClick}>
                          <Link to="/csir-net-science">
                            <FaBookOpen /> Live Online CSIR-NET Life Science
                          </Link>
                        </li> */}
                        {/* <li>
                    <Link to="/">
                      <FaBookOpen />
                      NET JRF Life science Lecture
                    </Link>
                  </li> */}
                      </div>
                    </ul>
                  </li>
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    <label for="drop-20" class="toggle">
                      <FaBookOpen /> GATE
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      GATE
                    </Link>
                    <input type="checkbox" id="drop-20" />
                    <ul className="addingimg1">
                      <div className="imaging11111">
                        <li>
                          {/* <!-- First Tier Drop Down --> */}
                          <label for="drop-5" class="toggle">
                            <FaBookOpen />
                            Gate (Biotechnology)
                          </label>
                          <Link to="">
                            <FaBookOpen />
                            Gate (Biotechnology)
                          </Link>
                          <input type="checkbox" id="drop-5" />
                          <ul className="ulline">
                            <div className="imaging1111">
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-syllabus">
                                  <FaBookOpen /> Gate Biotechnology Syllabus
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-pattern">
                                  <FaBookOpen /> Gate Biotechnology Exam Pattern
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-classroom">
                                  <FaBookOpen /> Gate Biotechnology CLassroom
                                  Coaching
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-correspondence">
                                  <FaBookOpen /> Gate Biotechnology
                                  Correspondence
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-biotechnology-coaching">
                                  <FaBookOpen /> Online Gate Biotechnology
                                  Coaching
                                </Link>
                              </li>
                            </div>
                          </ul>
                        </li>
                        <li>
                          {/* <!-- First Tier Drop Down --> */}
                          <label for="drop-5" class="toggle">
                            <FaBookOpen />
                            Gate (Life Science)
                          </label>
                          <Link to="/">
                            <FaBookOpen />
                            Gate (Life Science)
                          </Link>
                          <input type="checkbox" id="drop-5" />
                          <ul className="ulline">
                            <div className="imaging1111">
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-syllabus">
                                  <FaBookOpen /> Gate Life Science Syllabus
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-pattern">
                                  <FaBookOpen /> Gate Life Science Exam Pattern
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-classroom">
                                  <FaBookOpen /> Gate Life Science CLassroom
                                  Coaching
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-correspondence">
                                  <FaBookOpen /> Gate Life Science
                                  Correspondence
                                </Link>
                              </li>
                              <li onClick={handleMenuItemClick}>
                                <Link to="/gate-life-science-coaching">
                                  <FaBookOpen /> Online Gate Life Science
                                  Coaching
                                </Link>
                              </li>
                            </div>
                          </ul>
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li>
                    {/* <!-- Second Tier Drop Down --> */}
                    {/* <label for="drop-3" class="toggle">
                      <FaBookOpen />
                      GATE (Biotechnology)
                    </label>
                    <Link to="/">
                      <FaBookOpen />
                      GATE (Biotechnology)
                    </Link>
                    <input type="checkbox" id="drop-3" />
                    <ul className="addingimg1">
                      <div className="imaging11"></div>
                    </ul> */}
                  </li>
                </div>
              </ul>
            </li>
            <li onClick={handleMenuItemClick}>
              <Link to="/blog">
                <FaBloggerB />
                Blog
              </Link>
            </li>
            <li onClick={handleMenuItemClick}>
              <Link to="/">
                <PiExamFill />
                Online Test Series
              </Link>
            </li>
            <li onClick={handleMenuItemClick}>
              <Link to="/gallery">
                {" "}
                <RiGalleryLine />
                Gallery
              </Link>
            </li>
            <li>
              {/* <!-- First Tier Drop Down --> */}
              <label for="drop-5" class="toggle">
                <FaPhoneAlt />
                Contact Us
              </label>
              <Link to="/get-connected-locally">
                <FaPhoneAlt />
                Contact Us
              </Link>
              <input type="checkbox" id="drop-5" />
              <ul className="ulline">
                <div className="imaging111">
                  <li onClick={handleMenuItemClick}>
                    <Link to="/get-connected-locally">
                      <FaPhoneAlt />
                      Get Connected
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/connect-online">
                      <FaPhoneAlt />
                      Connected online
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/how-to-reach">
                      <FaPhoneAlt />
                      How To Reach
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/calendar">
                      <FaPhoneAlt />
                      Calender
                    </Link>
                  </li>
                  <li onClick={handleMenuItemClick}>
                    <Link to="/get-email-update">
                      <FaPhoneAlt />
                      Get Email Update
                    </Link>
                  </li>
                </div>
              </ul>
            </li>

            {/* <li><Link to="#">About</a></li> */}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
