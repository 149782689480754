import React from "react";
import Img1 from "./../../image/picturemessage.jpg";
import "./../../style/csir.css";
import { Link } from "react-router-dom";

const GateLifeSciCoaching = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">

      <h1>ONLINE GATE LIFE SCIENCES COACHING</h1>
        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          {/* <h5>REGULAR TEACHING PROGRAMME for CSIR NET Life Sciences</h5> */}
          <p>
            GATE Life Sciences Online Coaching in Delhi for Students of India
            Preparing for GATE Life Sciences Examination 2022 & 2023. If you are
            an aspirant of the Upcoming Examination of GATE & looking to crack
            GATE 2022 with Life Sciences as a subject, it is the right time to
            grab a seat in the Online Live Coaching Program of Odisha NET Academy (ONA). Odisha NET Academy (ONA)  is the most prominent coaching for GATE
            Life Science to students willing to Crack the GATE 2022 Examination.
            We offer the most comprehensive GATE Online Classes for Life
            Sciences to prepare you for GATE 2022. Online Coaching is also
            available for GATE Life Sciences
          </p>
          <h5>Salient Features for GATE Online Live Classes</h5>
          <p>
            <ul>
              <li>
                Online Live Classes and Class Notes (PDF) format/Streamed
                lecture for revision.
              </li>
              <li>
                Questions Booklets and Previous Year Solved Papers for Practice
                and simulation.
              </li>
              <li>
                Regular Online Topic to Topic Test to analyze & evaluate your
                knowledge.
              </li>
              <li>
                Separate Batches with Online Test Series and its Solution.
              </li>
              <li>
                The Classes will hold approx. 4 to 5 hours daily and 6 days a
                week for five months.
              </li>
              <li>
                Lectures by a team of Subject-wise Experienced Faculty Members.
              </li>
              <li>
                Competitive fee Structure (45% Lower than regular fee) to
                prepare you for examinations even in Covid situations.
              </li>
              <li>A study from Home through your laptop or mobile phone.</li>
              <li>
                Free Study Material & Books for Self Revision and preparation
                for examinations
              </li>
              <li>Online Doubt Clearing Session.</li>
              <li>We cover the entire syllabus in 5 month</li>
              <li>Guaranty Batch for GATE Examination is available</li>
              <li>
                Offline Regular Classroom Coaching & Weekend Classes will resume
                after Covid.
              </li>
            </ul>
          </p>
          {/* <h5>Fee for Live Online Coaching:</h5>
          <p>
            Rs.30000/- (Special Fee Discount of Rs.2000/- Applicable for
            Economically Poor Students)
          </p>
          <p>
            New Batches for CSIR NET Life Sciences are starting every week, Call
            us to reserve your seat (Only 50 Students Per Batch, Take your
            registration & reserve your seat by paying only Rs.5000/-)
          </p>
          <p data-aos="fade-left">
            <Link to="/">Take a Demo Class : Click Here to Start</Link>
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default GateLifeSciCoaching;
