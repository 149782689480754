import React from "react";
import "./../style/howtoreach.css";

export default function HowToReach() {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3740.5814035434482!2d85.82704667851257!3d20.35890440036891!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909018b70a799%3A0x1706b3ff35917fe6!2sOdisha%20NET%20Academy-ONA!5e0!3m2!1sen!2sin!4v1704626614866!5m2!1sen!2sin"
        width="100%"
        height="550px"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="mobile-margin-top"
      ></iframe>

      <div className="howtoreachrow">
        <div className="howtoreachcolumn">
          <h1>WHERE ARE WE?</h1>
          <p>
          Bhubaneswar is located in the
            heart of South  Bhubaneswar, very close to the  beach on Marine
            Drive. Important landmarks around the temple include the famous
            Babulnath temple (100mtrs), Bharatiya Vidya Bhavan(50mtrs) and
            Wilson College(350mtrs). The temple is situated midway on the street
            connecting Bharatiya Vidya Bhavan and Babulnath Temple.
          </p>
          <h3> Reaching by local train: </h3>
          <p>
             Bhubaneswar has a very efficient and well-connected sub-urban railway
            system. One can catch a local train from any part of  Bhubaneswar to come
            to Grant Road station on the western line. After exiting from the
            western side, one can hire a cab for “Hare Krishna Mandir near
            Bharitya Vidya Bhavan/Babulnath Temple”. Walking to the temple from
            Grant Rd. station also doesn’t take more than 10-15 minutes. Click
            here to view the  Bhubaneswar sub-urban railway map.
          </p>
          <h1>REACHING  Bhubaneswar</h1>
          <p>
             Bhubaneswar is the capital city of the Indian state of Maharashtra and
            India’s biggest metropolitan city. It is also regarded as India’s
            economic capital because of being the hub of commercial activities.
            The best season to visit  Bhubaneswar is between October to March.  Bhubaneswar
            is well connected to all major cities of India by an efficient
            network of air, rail and road. The Chhatrapati Shivaji International
            Airport is connected to all major cities by a network of flights.
             Bhubaneswar is well linked with other parts of the country by a huge
            network of trains. Those travelling from central, eastern and
            western parts of India can book their tickets till Chhatrapati
            Shivaji Terminus while travellers arriving from the north are served
            by  Bhubaneswar Central Station. Apart from these two major railheads,
            there are others such as Dadar Railway Station and Lokmanya Tilak
            Terminus serving the city of  Bhubaneswar. Buses, cabs and taxis are
            easily available from outside the railway stations.  Bhubaneswar is also
            perfectly connected by a road network to the rest of India.
          </p>
        </div>

        <div className="checkoutcolumn">
          <h1>BEFORE YOU ARRIVE</h1>
          <span className="howtoreachspan">We’re open from</span><br />
          <span className="howtoreachspan">5am – 1pm & 4:30pm – 9pm (Mon – Sat)</span><br />
          <span className="howtoreachspan">5am – 9pm (Sunday)</span><br />
          <p>
            The main darshan, however, closes at regular intervals for some time
            all through the day. For detailed darshan timings, please click
            here. Please ensure your arrival during these timings only, so that
            you can have an amazing darshan experience. To book an Uber / Ola
            cab from any part of  Bhubaneswar, just enter  as the
            destination.
          </p>

          <h1>GET DRIVING DIRECTIONS</h1>
          <button className="howtoreachbutton">FROM YOUR CURRENT LOCATION</button>

          <label htmlFor="">
          From any other starting address:
          </label>
          <input type="text" className="howtoreachinput"/><br />

          <button className="howtoreachbutton1">GET DIRECTIONS</button>

          <h1>ADDRESS</h1>

           
          <p>Sarala Vihar, Nageshwar Road,<br />
          In front of Gulmohar Apartment, Nandan Vihar,<br />
          Bhubaneswar, Odisha 751024</p>
        </div>
      </div>
    </>
  );
}
