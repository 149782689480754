import React from "react";
import utpannaekadashi from './../image/sci.png';
import ekadashi from './../image/sci.png';
import govardhanpuja from './../image/sci.png';
import understandinggrowth from './../image/sci.png';
import shrivamana from './../image/sci.png';
import nurturing from './../image/sci.png';
import "./../style/blog.css";
import { Link } from "react-router-dom";

export default function Blog() {
  return (
    <div className="blogdiv">
      <div className="blogheading">
        <h1 style={{fontWeight:"600"}}>Blog</h1>
        <p>
        Odisha NET Academy (ONA) is the pioneer CSIR NET Academy (For Life Sciences) in Bhubaneswar, Odisha. It was established in 2011 August 8th. Although the aim of our classes is to prepare the students for JRF-NET exam, we also provide additional useful information to reinforce their foundation so that they are equipped to face any interview or other Ph.D. entrance Exams. Thus the coaching is also beneficial for GATE, ICMR, DBT and various other entrance examinations.
        </p>
      </div>

      <div className="blogrow">
          <div className="blogcolumn">
          <a href="/" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src="https://st4.depositphotos.com/1811396/27616/v/1600/depositphotos_276168062-stock-illustration-online-education-background-or-homepage.jpg" alt="utpannaekadashi" />
          <p>Odisha NET Academy-ONA</p>
          </a>
        </div>
       
        
        <div className="blogcolumn">
          <a href="/" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src="https://wallpapercave.com/wp/wp9629378.png" alt="ekadashi-2023" />
          <p>Odisha NET Academy-ONA</p>
          </a>
        </div>

        <div className="blogcolumn">
          <a href="/" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxI6yPLJRMKJ6yut8R7VcYe8pJwN22RaDHfQ&usqp=CAU" alt="govardhanpuja" />
          <p>Odisha NET Academy-ONA</p>
          </a>
        </div>
      </div>

      <div className="blogrow">
        <div className="blogcolumn">
          <a href="/" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src="https://www.shutterstock.com/image-vector/elearning-concept-boy-studying-home-260nw-1709367028.jpg" alt="january" />
          <p>Odisha NET Academy-ONA</p>
          </a>
        </div>

        <div className="blogcolumn">
          <a href="/" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src="https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400076/55847740-modern-thin-line-design-concept-for-e-learning-website-banner-vector-illustration-concept-for-online.jpg" alt="shrivamana" />
          <p>Odisha NET Academy-ONA</p>
          </a>
        </div>

        <div className="blogcolumn">
          <a href="/" style={{textDecoration:"none",color:"black"}}>
          <img className="blogimage" src="https://i.pinimg.com/originals/73/bb/bc/73bbbc50cb823fcee6200e745099710c.jpg" alt="nurturing" />
          <p>Odisha NET Academy-ONA</p>
          </a>
        </div>
      </div>

      {/* <div className="blogrow">
        <div className="blogcolumn">
          <img className="blogimage" src={april} alt="january" />
          <p>Researching Charitable Organizations: Evaluating Nonprofits for Effective Giving</p>
        </div>
        <div className="blogcolumn">
          <img className="blogimage" src={january} alt="january" />
          <p>The Significance of Pavitra Ekadashi</p>
        </div>
        <div className="blogcolumn">
          <img className="blogimage" src={january} alt="january" />
          <p>Nandotsava: The birth ceremony of Lord Sri Krishna</p>
        </div>
      </div> */}
    </div>
  );
}
