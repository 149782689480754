import React from "react";
import Img1 from "./../../image/sci.png";
import "./../../style/csir.css";
import { Link } from "react-router-dom";


const CsirNetSci = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">

      <h1>CSIR NET LIFE SCIENCES ONLINE COACHING</h1>
        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <p>
            CSIR NET Life Sciences Online Coaching in Delhi for Students of
            India Preparing for CSIR NET Life Sciences Examination 2021 & 2022.
            If you are an aspirant of the Upcoming Examination of CSIR NET &
            Expecting JRF with you, it is the right time to grab a seat in the
            Online Live Coaching Program of Odisha NET Academy (ONA) . Odisha NET Academy (ONA) is the most prominent coaching for CSIR NET JRF Life Science
            Students to Crack CSIR NET 2021 & CSIR NET 2022 Examination. We
            offer the most comprehensive CSIR NET Online Classes for Life
            Sciences to prepare you for CSIR NET Examination 2021. Online
            Coaching is also available for GATE Life Sciences
          </p>
          <p>
            <b>Salient Features for NET Online Live Classes</b> <br />
            Online Live Classes and Class Notes (PDF) format/Streamed lecture
            for revision. <br />
            Questions Booklets and Previous Year Solved Papers for Practice and
            simulation. <br />
            Regular Online Topic to Topic Test to analyze & evaluate your
            knowledge. <br />
            Separate IIT-JAM/GATE Biotech, CSIR NET/GATE Life Sciences/Physics
            Online Test Series with Solution. <br />
            The Classes will hold approx. 4 to 5 hours daily and 6 days a week
            for five months. <br />
            Lectures by a team of Subject-wise Experienced Faculty Members.{" "}
            <br />
            Competitive fee Structure (45% Lower than regular fee) to prepare
            you for examinations even in Covid situations. <br />
            A study from Home through your laptop or mobile phone. <br />
            Free Study Material & Books for Self Revision and preparation for
            examinations <br />
            Online Doubt Clearing Session. <br />
            We cover the entire syllabus in 5 month <br />
            Guaranty Batch for CSIR NET Examination is available <br />
            Offline Regular Classroom Coaching & Weekend Classes will resume
            after Covid.
          </p>
          {/* <h5>Fee for Live Online Coaching:</h5>
          <p>
            Rs.30000/- (Special Fee Discount of Rs.2000/- Applicable for
            Economically Poor Students)
          </p>
          <p>
            New Batches for CSIR NET Life Sciences are starting every week, Call
            us to reserve your seat (Only 50 Students Per Batch, Take your
            registration & reserve your seat by paying only Rs.5000/-)
          </p>
          <p data-aos="fade-left">
            <Link to="/">Take a Demo Class : Click Here to Start</Link>
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default CsirNetSci;
