import React, { useEffect, useState } from "react";
import { imageDb } from "../../firebaseConfig";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import "./../../style/studymaterial.css";
import { Link } from "react-router-dom";
import i1 from "./../../image/download.jpeg";
import i2 from "./../../image/download2.jpeg";
import i3 from "./../../image/download3.jpeg";
import i4 from "./../../image/download4.jpeg";
import i5 from "./../../image/download5.jpeg";
const StudyMaterial = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const ImageArray = [i1, i2, i3, i4, i5];
  const cardTitles = [
    "CSIR NET Life Sciences",
    "GATE (Biotechnology)",
    "GATE (Life Sciences)",
  ];

  useEffect(() => {
    listAll(ref(imageDb, "studymaterial")).then((imgs) => {
      console.log(imgs);
      imgs.items.forEach((val) => {
        getDownloadURL(val).then((url) => {
          setImageUrls((prevUrls) => {
            if (!prevUrls.includes(url)) {
              return [...prevUrls, url];
            }
            return prevUrls;
          });
        });
      });
    });
  }, []);
  return (
    <div className="studymaterial">
      <h2>Study Material</h2>
      <div>
        <div
          id="myCarousel"
          class="carousel slide"
          data-ride="carousel"
          data-interval="2000"
        >
          <div className="carousel-inner">
            {imageUrls
              ? imageUrls.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <div className="card">
                      <img
                        src={imageUrl}
                        className="card-img-top"
                        alt={`Slide ${index + 1}`}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{cardTitles[index]}</h5>
                        <Link to="#" className="btn btn-warning">
                          Explore More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : ImageArray.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <div className="card">
                      <img
                        src={imageUrl}
                        className="card-img-top"
                        alt={`Slide ${index + 1}`}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{cardTitles[index]}</h5>
                        <Link to="#" className="btn btn-warning">
                          Explore More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyMaterial;
