import React from "react";
import New from "./../image/new.jpg";
import "./../style/regularclasses.css";
import { SiGoogleclassroom } from "react-icons/si";
import { FaCalendarWeek } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa6";
import { SlCamrecorder } from "react-icons/sl";
import { GrGroup } from "react-icons/gr";
import { TiNews } from "react-icons/ti";
import { FaBook } from "react-icons/fa";
import { PiExam } from "react-icons/pi";

const RegularFee = () => {
  return (
    <section className="regularclass">
      <div className="regularfeeheading">
        <h2>
          <img src={New} alt="" /> REGULAR / WEEKEND / ONLINE & CORRESPONDENCE
          FEE DETAILS
        </h2>
      </div>
      <div data-aos="zoom-in-left" className="allregularclasslist">
        <div className="onecardregular">
          <SiGoogleclassroom />
          <h2>Regular Classroom Coaching</h2>
          <p>
            CSIR NET Life Sciences, GATE Biotechnology, IIT-JAM/M.Sc Entrance
            Life Sciences & Biotechnology Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div>
        {/* <div data-aos="zoom-in-right" className="onecardregular">
          <FaCalendarWeek />
          <h2>Weekend Coaching</h2>
          <p>
            Best Weekend Coaching for CSIR NET Life Sciences, GATE
            Biotechnology, IIT-JAM/M.Sc Life Sciences & Biotechnology Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div> */}
        <div data-aos="zoom-in-left" className="onecardregular">
          <FaLaptopCode />
          <h2>Online Coaching</h2>
          <p>
            CSIR NET Life Sciences, GATE Biotechnology, IIT-JAM/M.Sc Entrance
            Life Sciences & Biotechnology Online Coaching.
          </p>
          <button className="button-62">Read more</button>
        </div>
        <div data-aos="zoom-in-right" className="onecardregular">
          <SlCamrecorder />
          <h2>Video Lecture</h2>
          <p>
            Recorded Video Lectures for quick revision of subjects of CSIR NET
            Life Sciences, GATE Biotechnology, IIT-JAM/M.Sc Entrance Life
            Sciences & Biotechnology Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div>
        {/* <div data-aos="zoom-in-left" className="onecardregular">
          <GrGroup />
          <h2>Correspondence Courses</h2>
          <p>
            Comprehensive Correspondence Courses of CSIR NET Life Sciences, GATE
            Biotechnology, IIT-JAM/M.Sc Entrance Life Sciences & Biotechnology
            Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div> */}
        <div data-aos="zoom-in-right" className="onecardregular">
          <TiNews />
          <h2>Previous year Q&A</h2>
          <p>
            Latest & Revised previous year question and answer to Prepare Exams of CSIR NET Life
            Sciences, GATE Biotechnology, IIT-JAM/M.Sc Entrance Life Sciences &
            Biotechnology Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div>
        <div data-aos="zoom-in-left" className="onecardregular">
          <FaBook />
          <h2>Books</h2>
          <p>
            India's No-1 Books to grab the success in the exams of CSIR NET Life
            Sciences, GATE Biotechnology, IIT-JAM/M.Sc Entrance Life Sciences &
            Biotechnology Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div>
        <div data-aos="zoom-in-right" className="onecardregular">
          <PiExam />
          <h2>Online Test Series</h2>
          <p>
            Evaluate yourself before appearing exams of CSIR NET Life Sciences,
            GATE Biotechnology, IIT-JAM/M.Sc Entrance Life Sciences &
            Biotechnology Entrance.
          </p>
          <button className="button-62">Read more</button>
        </div>
      </div>
    </section>
  );
};

export default RegularFee;
