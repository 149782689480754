import React from "react";
import Img1 from "./../../image/corres.jpg";
import "./../../style/csir.css";

const GateBioCorres = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">

      <h1>GATE BIOTECHNOLOGY CORRESPONDENCE COURSES</h1>
        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <p>
            Odisha NET Academy (ONA)  offers Correspondence Courses in GATE
            Biotechnology. Our Correspondence Study Material for GATE
            Biotechnology have been prepared by continuous and prolonged efforts
            of professors and lecturer of JNU, IIT & Delhi University. In Order
            to Crack the examination of GATE Biotechnology, you need to focus on
            our correspondence study materials, solved papers, and practice
            sample papers instead of wandering in a number of books.
          </p>
          <p>
            Our GATE Life Sciences Correspondence Study material is concise and
            points to point so that you can prepare for your examination in a
            limited time. We have also arranged to solve your doubt in weekends
            classes for GATE Life Sciences by our Expert Teachers.
          </p>
          <p>
            Our GATE Biotechnology Correspondence Study material is concise and
            points to point so that you can prepare for your examination in a
            limited time. We have also arranged to solve your doubt in weekends
            classes for GATE Biotechnology by our Expert Teachers.
          </p>
          <p>
            <b>The Benefits of Correspondence Course</b> <br />
            Many working students wish to qualify for the exam for the
            advancement of their career, but cannot spare time to attend the
            regular classroom courses. These working students can always avail
            of our correspondence programme. This programme basically provides
            them the study material which is prepared topic-wise, along with
            many question papers. The correspondence material is absolutely
            self-explanatory and will compensate for the absence of classroom
            coaching. The material covers 80 to 90% syllabus and is more than
            sufficient for qualifying for the exam. This programme offers the
            simplicity of learning at your home and at your pace while
            qualifying for your Doctorate degree. Also those in the job, but
            with a desire to clear this exam can achieve their goal without
            quitting their regular work. The biggest benefit of the course is
            that you get to learn at your pace as per your schedule. The
            correspondence material is self-explanatory and thus will compensate
            for the absence of a teacher in a distant learning mode. However,
            for clearing the difficulties of the students, we clear the doubt
            telephonically or through the internet/email. The questions asked in
            the CSIR UGC JRF NET Life Science exam are of analytical nature and
            designed to test a candidates knowledge of scientific concepts. The
            course material is written in the most scientific way to serve this
            purpose. The authors are top rankers of the JRF/NET/GATE &
            Professors are from top universities (& Institutes)
            JNU/DU/IITD/AIIMS etc. Once you start following our material, you
            need not refer to any other study material. Whats more, the material
            is as per the latest pattern upgraded to reflect the latest JRF/NET
            and Ph. D. entrance trends. Without exception, all students whove
            referred to our material for a significant amount of time, place it
            far above other courses in terms of quality.
          </p>
        </div>
      </div>
    </section>
  );
};

export default GateBioCorres;
