import React from "react";
import "./../../style/hometable.css";
const HomeTable = () => {
  return (
    <div>
      <div data-aos="zoom-in-right" class="container">
        <h2>New Batch Schedule</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Classroom Coaching</th>
              <th>Online Class Coaching</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Content 1</td>
              <td>Content 2</td>
            </tr>
            <tr>
              <td>Content 3</td>
              <td>Content 4</td>
            </tr>
            <tr>
              <td>Content 1</td>
              <td>Content 2</td>
            </tr>
            <tr>
              <td>Content 3</td>
              <td>Content 4</td>
            </tr>
            <tr>
              <td>Content 1</td>
              <td>Content 2</td>
            </tr>
            <tr>
              <td>Content 3</td>
              <td>Content 4</td>
            </tr>
            <tr>
              <td>Content 3</td>
              <td>Content 4</td>
            </tr>
          </tbody>
        </table>

        <tr className="">
          <td>Content 1</td>
          <td>Content 2</td>
        </tr>
        <tr>
          <td>Content 3</td>
          <td>Content 4</td>
        </tr>
      </div>
    </div>
  );
};

export default HomeTable;
