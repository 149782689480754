// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBPSO32S-ijkHr5La-7fL3vX7XfyKu3hFg",
  authDomain: "odishanetacademynew.firebaseapp.com",
  projectId: "odishanetacademynew",
  storageBucket: "odishanetacademynew.appspot.com",
  messagingSenderId: "716222982344",
  appId: "1:716222982344:web:55852a1d47de02cab5ddfd",
  measurementId: "G-W4233FBZCD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseApp = initializeApp(firebaseConfig);
export const imageDb = getStorage(app);
const auth = getAuth(firebaseApp);

export { auth };
