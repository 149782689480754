import React from "react";
import "./../style/approach.css";

const OurApproach = () => {
  return (
    <section className="ourapproach">
      <h1>Our Approach</h1>
      <div class="rowwing">
        <div class="box">
          <div class="icon">01</div>
          <div class="content">
            We are conducting weekly tests on Sundays for the taught portion
            along with the surprise tests in between.
          </div>
        </div>
        <div class="box">
          <div class="icon">02</div>
          <div class="content">
            Special guest lectures are organized from time to time, which are
            delivered by eminent academicians and professors from prestigious
            universities and institutes.
          </div>
        </div>
        <div class="box">
          <div class="icon">03</div>
          <div class="content">
            The evaluation and discussion are done on the same day.
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurApproach;
