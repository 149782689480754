import React from "react";
import "./../style/feeStructure.css"

const FeeStructure = () => {
  return (
    <div>
      <div class="container">
        <h2>Fee Structure</h2>
        <p>Regular/Online & Correspondence Fee Detail.</p>

        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Crack NET - JRF Life Sciences Courses</th>
              <th>GBA CODE</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Regular Classroom Coaching for CSIR NET Life Sciences : Offline
              </td>
              <td>OANCSIT-1</td>
              <td>Rs.18,000/-</td>
            </tr>
            <tr>
              <td>
              Regular Classroom Coaching for CSIR NET Life Sciences : Online
              </td>
              <td>OANCSITON-2</td>
              <td>Rs.15,000/-</td>
            </tr>
            <tr>
              <td>Regular Classroom Coaching for GATE NET: Offline</td>
              <td>OANGATE-3</td>
              <td>Rs.18,000/-</td>
            </tr>
            <tr>
              <td>
              Regular Classroom Coaching for GATE NET : Online
              </td>
              <td>OANGATEON-4</td>
              <td>Rs.15,000/-</td>
            </tr>
            <tr>
              <td>All India GATE NET JRF Test Series Online</td>
              <td>OANGATETEST-5</td>
              <td>Rs.2000/-</td>
            </tr>
            <tr>
              <td>
              All India GATE NET JRF Test Series Offline
              </td>
              <td>OANGATETEST-6</td>
              <td>Rs.2,000/-</td>
            </tr>
            <tr>
              <td>All India CSIR NET JRF Life Science Test Series Offline</td>
              <td>OANCSIRTEST-7</td>
              <td>Rs.2,000/-</td>
            </tr>
            <tr>
              <td>All India CSIR NET JRF Life Science Test Series Online</td>
              <td>OANCSIRTEST-8</td>
              <td>Rs.2,000/-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FeeStructure;
