import "./../style/gallery.css"; // Assuming you save the CSS in a file named YourComponent.css
import React, { useEffect, useState } from "react";
import { imageDb } from "../firebaseConfig";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import Image1 from "./../image/1.jpg";
import Image2 from "./../image/2.jpg";
import Image3 from "./../image/3.jpg";
import Image4 from "./../image/4.jpg";
import Image5 from "./../image/5.jpg";
import Image6 from "./../image/6.jpg";
import Image7 from "./../image/7.jpg";
import Image8 from "./../image/8.jpg";
import Image9 from "./../image/9.jpg";
import Image10 from "./../image/10.jpg";

const GalleryPhoto = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const ImageArray = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
  ];

  useEffect(() => {
    const gallery = document.querySelector("#gallery");
    const getVal = (elem, style) =>
      parseInt(window.getComputedStyle(elem).getPropertyValue(style));
    const getHeight = (item) =>
      item.querySelector(".content").getBoundingClientRect().height;

    const resizeAll = () => {
      const altura = getVal(gallery, "grid-auto-rows");
      const gap = getVal(gallery, "grid-row-gap");
      gallery.querySelectorAll(".gallery-item").forEach((item) => {
        const el = item;
        el.style.gridRowEnd = `span ${Math.ceil(
          (getHeight(item) + gap) / (altura + gap)
        )}`;
      });
    };
    gallery.querySelectorAll("img").forEach((item) => {
      item.classList.add("byebye");
      if (item.complete) {
        console.log(item.src);
      } else {
        item.addEventListener("load", () => {
          const altura = getVal(gallery, "grid-auto-rows");
          const gap = getVal(gallery, "grid-row-gap");
          const gitem = item.parentElement.parentElement;
          gitem.style.gridRowEnd = `span ${Math.ceil(
            (getHeight(gitem) + gap) / (altura + gap)
          )}`;
          item.classList.remove("byebye");
        });
      }
    });

    window.addEventListener("resize", resizeAll);

    gallery.querySelectorAll(".gallery-item").forEach((item) => {
      item.addEventListener("click", () => {
        item.classList.toggle("full");
      });
    });

    return () => {
      window.removeEventListener("resize", resizeAll);
    };
  }, []);

  useEffect(() => {
    listAll(ref(imageDb, "galleryImages")).then((imgs) => {
      console.log(imgs);
      imgs.items.forEach((val) => {
        getDownloadURL(val).then((url) => {
          setImageUrls((prevUrls) => {
            if (!prevUrls.includes(url)) {
              return [...prevUrls, url];
            }
            return prevUrls;
          });
        });
      });
    });
  }, []);
  // console.log("Image array data", ImageArray);
  return (
    <div>
      <div className="gallery" id="gallery">
        {imageUrls
          ? imageUrls.map((imageUrl, index) => (
              <div key={index} className="gallery-item">
                <div className="content">
                  <img
                    data-aos="flip-left"
                    src={imageUrl}
                    alt={`Image ${index}`}
                  />
                </div>
              </div>
            ))
          : ImageArray.map((imageUrl1, index) => (
              <div key={index} className="gallery-item">
                <div className="content">
                  <img
                    data-aos="flip-left"
                    src={imageUrl1}
                    alt={`Image ${index}`}
                  />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default GalleryPhoto;
