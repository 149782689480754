import React from "react";
import Img1 from "./../../image/corres.jpg";
import "./../../style/csir.css";

const GateLifeSciDel = () => {
  return (
    <section className="csirmention">
      <div className="topgate">
        <div className="innergate">

      <h1>GATE LIFE SCIENCES SYLLABUS</h1>
        </div>
      </div>
      <div className="inforcsir">
        <img  data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <p>
            <b>XL (Life Sciences under XL)</b>
            A candidate appearing in the XL paper has to answer the following
            section <br />
            Section H - Chemistry (compulsory) <br />
            GA - General Aptitude (compulsory) <br />
            Any two of XL sections I to M
          </p>
          <p>
            The choice of two sections from I to M can be made during the
            examination after viewing the questions. Only two optional sections
            can be answered by the candidate. A candidate wishing to change
            midway through the examination to another optional section must
            first choose to deselect one of the previously chosen optional
            sections (I to M).
          </p>
        </div>
      </div>
      <div className="gatelifesci">
        <img data-aos="fade-left" src={Img1} alt="" />
        <div className="gatelifesciinner">
          <h5>XL- SECTION H. Chemistry (Compulsory)</h5>
          <p>
            <b>Atomic structure and periodicity:</b> Plancks quantum theory,
            wave-particle duality, uncertainty principle, quantum mechanical
            model of hydrogen atom; electronic configuration of atoms; periodic
            table and periodic properties; ionization energy, electron affinity,
            electronegativity, atomic size.
          </p>
          <p>
            <b>Structure and bonding:</b> Ionic and covalent bonding, M.O. and
            V.B. approach for diatomic molecules, VSEPR theory, and shape of
            molecules, hybridization, resonance, dipole moment, structure
            parameters such as bond length, bond angle and bond energy, hydrogen
            bonding, van der Waals interactions. Ionic solids, ionic radii,
            lattice energy (Born-Haber Cycle).
          </p>
          <p>
            <b>s.p. and d Block Elements:</b> Oxides, halides, and hydrides of
            alkali and alkaline earth metals, B, Al, Si, N, P, and S, general
            characteristics of 3d elements, coordination complexes: valence bond
            and crystal field theory, color, geometry, and magnetic properties.
          </p>
          <p>
            <b>Chemical Equilibria:</b> Colligative properties of solutions,
            ionic equilibria in solution, solubility product, common ion effect,
            hydrolysis of salts, pH, buffer and their applications in chemical
            analysis, equilibrium constants (Kc, Kp, and Kx) for homogeneous
            reactions,
          </p>
          <p>
            <b>Electrochemistry:</b> Conductance, Kohlrausch law, Half Cell
            potentials, emf, Nernst equation, galvanic cells, thermodynamic
            aspects, and their applications.
          </p>
          <p>
            <b>Reaction Kinetics:</b> Rate constant, order of reaction,
            molecularity, activation energy, zero, first and second-order
            kinetics, catalysis, and elementary enzyme reactions.
          </p>
          <p>
            <b>Thermodynamics:</b> First law, reversible and irreversible
            processes, internal energy, enthalpy, Kirchoffs equation, the heat
            of reaction, Hess law, the heat of formation, Second law, entropy,
            free energy, and work function. Gibbs-Helmholtz equation,
            Clausius-Clapeyron equation, free energy change and equilibrium
            constant, Croutons rule, Third law of thermodynamics.
          </p>
          <p>
            <b>Basis of Organic Reactions Mechanism:</b> Elementary treatment of
            SN1, SN2, E1, and E2 reactions, Hoffmann and Saytzeff rules,
            Addition reactions, Markonikoff rule, and Kharash effect,
            Diels-Alder reaction, aromatic electrophilic substitution,
            orientation effect as exemplified by various functional groups.
            Identification of functional groups by chemical tests
          </p>
          <p>
            <b>Structure-Reactivity Correlations:</b> Acids and bases,
            electronic and steric effects, optical and geometrical isomerism,
            tautomerism, conformers, the concept of aromaticity
          </p>
          <p>
            <b>Choose Any two of XL sections I to M syllabus as given </b>
          </p>
        </div>
      </div>
      <div className="gatelifesci1">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="gatelifesciinner1">
          <h5>XL- SECTION I. BIOCHEMISTRY</h5>
          <p>
            Organization of life. Importance of water. Cell structure and
            organelles. Structure and function of biomolecules: Amino acids,
            Carbohydrates, Lipids, Proteins, and Nucleic acids. Biochemical
            separation techniques and characterization: ion exchange, size
            exclusion, and affinity chromatography, electrophoresis, UV-visible,
            fluorescence, and Mass spectrometry. Protein structure, folding, and
            function: Myoglobin, Hemoglobin, Lysozyme, Ribonuclease A,
            Carboxypeptidase, and Chymotrypsin. Enzyme kinetics including its
            regulation and inhibition, Vitamins, and Coenzymes.
          </p>
          <p>
            Metabolism and bioenergetics. Generation and utilization of ATP.
            Metabolic pathways and their regulation: glycolysis, TCA cycle,
            pentose phosphate pathway, oxidative phosphorylation,
            gluconeogenesis, glycogen, and fatty acid metabolism. Metabolism of
            Nitrogen-containing compounds: nitrogen fixation, amino acids, and
            nucleotides. Photosynthesis: the Calvin cycle.
          </p>
          <p>
            Biological membranes. Transport across membranes. Signal
            transduction; hormones and neurotransmitters.
          </p>
          <p>
            DNA replication, transcription, and translation. Biochemical
            regulation of gene expression. Recombinant DNA technology and
            applications: PCR, site-directed mutagenesis, and DNA microarray.
          </p>
          <p>
            Immune system. Active and passive immunity. Complement system.
            Antibody structure, function, and diversity. Cells of the immune
            system: T, B, and macrophages. T and B cell activation. Major
            histocompatibility complex. T cell receptor. Immunological
            techniques: Immunodiffusion, immunoelectrophoresis, RIA, and ELISA.
          </p>
        </div>
      </div>
      <div className="gatelifesci">
        <img data-aos="fade-left" src={Img1} alt="" />
        <div className="gatelifesciinner">
          <h5>SECTION J. BIOTECHNOLOGY</h5>
          <p>
            Advanced techniques in gene expression and analysis: PCR and RT-PCR,
            microarray technology, DNA fingerprinting and recombinant DNA
            technology; prokaryotic and eukaryotic expression systems; Vectors:
            plasmids, phages, cosmids, and BAC.
          </p>
          <p>
            The architecture of plant genome; plant tissue culture techniques;
            methods of gene transfer into plant cells and development of
            transgenic plants; manipulation of phenotypic traits in plants;
            plant cell fermentation and production of secondary metabolites
            using suspension/immobilized cell culture; expression of animal
            protein in plants; genetically modified crops.
          </p>
          <p>
            Animal cell metabolism and regulation; cell cycle; primary cell
            culture; nutritional requirements for animal cell culture;
            techniques for mass culture of animal cell lines; application of
            animal cell culture for production of vaccines, growth hormones;
            interferons, cytokines, and therapeutic proteins; hybridoma
            technology and gene knockout; stem cells and its application in
            organ synthesis; gene therapy; transgenic animals and molecular
            pharming.
          </p>
          <p>
            <b> Industrial bioprocesses:</b> microbial production of organic
            acids, amino acids, proteins, polysaccharides, lipids,
            polyhydroxyalkanoates, antibiotics, and pharmaceuticals; methods and
            applications of immobilization of cells and enzymes; kinetics of
            soluble and immobilized enzymes; biosensors; biofuels;
            biopesticides; environmental bioremediation.
          </p>
          <p>
            Microbial growth kinetics; batch, fed-batch, and continuous culture
            of microbial cells; media for industrial fermentations;
            sterilization of air and media, design and operation of stirred
            tank, airlift, plug flow, packed bed, fluidized bed, membrane, and
            hollow fiber reactors; aeration and agitation in aerobic
            fermentations; bioprocess calculations based on material and energy
            balance; Downstream processing in industrial biotechnology:
            filtration, precipitation, centrifugation, cell disintegration,
            solvent extraction, and chromatographic separations, membrane
            filtration, aqueous two-phase separation.
          </p>
          <p>Bioinformatics; genomics; proteomics and computational biology.</p>
        </div>
      </div>
      <div className="gatelifesci1">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="gatelifesciinner1">
          <h5>XL- SECTION SECTION K. BOTANY</h5>
          <p>
            <b>Plant Systematics:</b> Systems of classification
            (non-phylogenetic vs. phylogenetic - outline), plant groups,
            molecular systematics.
          </p>
          <p>
            <b>Plant Anatomy:</b> Plant cell structure, organization,
            organelles, cytoskeleton, cell wall and membranes; anatomy of root,
            stem, and leaves, meristems, vascular system, their ontogeny,
            structure and functions, secondary growth in plants, and stellar
            organization.
          </p>
          <p>
            <b>Morphogenesis & Development:</b> Cell cycle, cell division, the
            life cycle of an angiosperm, pollination, fertilization,
            embryogenesis, seed formation, seed storage proteins, seed dormancy,
            and germination.
          </p>
          <p>
            Concept of cellular totipotency, clonal propagation; organogenesis
            and somatic embryogenesis, artificial seed, somaclonal variation,
            secondary metabolism in plant cell culture, embryo culture, in vitro
            fertilization.
          </p>
          <p>
            <b>Physiology and Biochemistry:</b> Plant water relations, transport
            of minerals and solutes, stress physiology, stomatal physiology,
            signal transduction, N2 metabolism, photosynthesis,
            photorespiration; respiration, Flowering: photoperiodism and
            vernalization, biochemical mechanisms involved in flowering;
            molecular mechanism of senescence and aging, biosynthesis, mechanism
            of action and physiological effects of plant growth regulators,
            structure and function of biomolecules, (proteins, carbohydrates,
            lipids, nucleic acid), enzyme kinetics.
          </p>
          <p>
            <b>Genetics:</b> Principles of Mendelian inheritance, linkage,
            recombination, genetic mapping; extrachromosomal inheritance;
            prokaryotic and eukaryotic genome organization, regulation of gene
            expression, gene mutation, and repair, chromosomal aberrations
            (numerical and structural), transposons.
          </p>
          <p>
            <b>Plant Breeding and Genetic Modification:</b> Principles, methods
            as selection, hybridization, heterosis; male sterility, genetic maps
            and molecular markers, sporophytic and gametophytic
            self-incompatibility, haploidy, triploidy, somatic cell
            hybridization, marker-assisted selection, gene transfer methods viz.
            direct and vector-mediated, plastid transformation, transgenic
            plants and their application in agriculture, molecular pharming,
            plantibodies.
          </p>
          <p>
            <b>Economic Botany:</b> A general account of economically and
            medicinally important plants- cereals, pulses, plants yielding
            fibers, timber, sugar, beverages, oils, rubber, pigments, dyes,
            gums, drugs, and narcotics. Economic importance of algae, fungi,
            lichen, and bacteria.
          </p>
          <p>
            <b>Plant Pathology:</b> Nature and classification of plant diseases,
            diseases of important crops caused by fungi, bacteria, and viruses,
            and their control measures, the mechanism(s) of pathogenesis and
            resistance, molecular detection of pathogens; plant-microbe
            beneficial interactions.
          </p>
          <p>
            <b>Ecology and Environment:</b> Ecosystems as types, dynamics,
            degradation, ecological succession; food chains and energy flow;
            vegetation types of the world, pollution and global warming,
            speciation, and extinction, conservation strategies,
            cryopreservation, phytoremediation.
          </p>
        </div>
      </div>
      <div className="gatelifesci">
        <img data-aos="fade-left" src={Img1} alt="" />
        <div className="gatelifesciinner">
          <h5>XL- SECTION L. MICROBIOLOGY</h5>
          <p>
            <b>Historical Perspective:</b> Discovery of microbial world Landmark
            discoveries relevant to the field of microbiology; Controversy over
            a spontaneous generation; Role of microorganisms in transformation
            of organic matter and in the causation of diseases.
          </p>
          <p>
            <b>Methods in Microbiology:</b> Pure culture techniques; Theory and
            practice of sterilization; Principles of microbial nutrition;
            Enrichment culture techniques for isolation of microorganisms;
            Light-phase contrast- and electron microscopy.
          </p>
          <p>
            <b>Microbial Taxonomy and Diversity:</b> Bacteria, Archea, and their
            broad classification; Eukaryotic microbes: Yeasts, molds, and
            protozoa; Viruses and their classification; Molecular approaches to
            microbial taxonomy.
          </p>
          <p>
            <b>Prokaryotic and Eukaryotic Cells:</b> Structure and Function:
            Prokaryotic Cells: cell walls, cell membranes, mechanisms of solute
            transport across membranes, Flagella and Pili, Capsules, Cell
            inclusions like endospores and gas vesicles; Eukaryotic cell
            organelles: Endoplasmic reticulum, Golgi apparatus, mitochondria,
            and chloroplasts.
          </p>
          <p>
            <b>Microbial Growth:</b> Definition of growth; Growth curve;
            Mathematical expression of exponential growth phase; Measurement of
            growth and growth yields; Synchronous growth; Continuous culture;
            Effect of environmental factors on growth.
          </p>
          <p>
            <b>Control of Micro-organisms:</b> Effect of physical and chemical
            agents; Evaluation of the effectiveness of antimicrobial agents.
          </p>
          <p>
            <b>Microbial Metabolism: Energetics:</b> redox reactions and
            electron carriers; An overview of metabolism; Glycolysis;
            Pentose-phosphate pathway; Entner-Doudoroff pathway; Glyoxalate
            pathway; The citric acid cycle; Fermentation; Aerobic and anaerobic
            respiration; Chemolithotrophy; Photosynthesis; Calvin cycle;
            Biosynthetic pathway for fatty acids synthesis; Common regulatory
            mechanisms in the synthesis of amino acids; Regulation of major
            metabolic pathways.
          </p>
          <p>
            <b> Microbial Diseases and Host-Pathogen Interaction:</b> Normal
            microbiota; Classification of infectious diseases; Reservoirs of
            infection; Nosocomial infection; Emerging infectious diseases;
            Mechanism of microbial pathogenicity; Nonspecific defense of host;
            Antigens and antibodies; Humoral and cell-mediated immunity;
            Vaccines; Immune deficiency; Human diseases caused by viruses,
            bacteria, and pathogenic fungi.
          </p>
          <p>
            <b>Chemotherapy/Antibiotics:</b> General characteristics of
            antimicrobial drugs; Antibiotics: Classification, mode of action and
            resistance; Antifungal and antiviral drugs.
          </p>
          <p>
            <b>Microbial Genetics:</b> Types of mutation; UV and chemical
            mutagens; Selection of mutants; Ames test for mutagenesis; Bacterial
            genetic system: transformation, conjugation, transduction,
            recombination, plasmids, transposons; DNA repair; regulation of gene
            expression: repression and induction; Operon model; Bacterial genome
            with special reference to E.coli; Phage and its life cycle; RNA
            phages; RNA viruses; Retroviruses; the Basic concept of microbial
            genomics.
          </p>
          <p>
            <b> Microbial Ecology:</b> Microbial interactions; Carbon, sulfur,
            and nitrogen cycles; Soil microorganisms associated with vascular
            plants.
          </p>
        </div>
      </div>
      <div className="gatelifesci1">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="gatelifesciinner1">
          <h5>XL- SECTION M. ZOOLOGY</h5>
          <p>
            <b>Animal world:</b> Animal diversity, distribution, systematics and
            classification of animals, phylogenetic relationships.
          </p>
          <p>
            <b>Evolution:</b> Origin and history of life on earth, theories of
            evolution, natural selection, adaptation, speciation.
          </p>
          <p>
            <b> Genetics:</b> Principles of inheritance, the molecular basis of
            heredity, mutations, cytoplasmic inheritance, linkage, and mapping
            of genes.
          </p>
          <p>
            <b>Biochemistry and Molecular Biology:</b> Nucleic acids, proteins,
            lipids, and carbohydrates; replication, transcription, and
            translation; regulation of gene expression, organization of the
            genome, Krebs cycle, glycolysis, enzyme catalysis, hormones, and
            their actions, vitamins.
          </p>
          <p>
            <b>pCell Biology:</b> Structure of cell, cellular organelles and
            their structure and function, cell cycle, cell division,
            chromosomes, and chromatin structure. Eukaryotic gene organization
            and expression (Basic principles of signal transduction).
          </p>
          <p>
            <b>Animal Anatomy and Physiology:</b> Comparative physiology, the
            respiratory system, circulatory system, digestive system, the
            nervous system, the excretory system, the endocrine system, the
            reproductive system, the skeletal system, osmoregulation.
          </p>
          <p>
            <b>Parasitology and Immunology:</b> Nature of parasite,
            host-parasite relation, protozoan and helminthic parasites, the
            immune response, cellular and humoral immune response, evolution of
            the immune system.
          </p>
          <p>
            <b>Development Biology:</b> Embryonic development, cellular
            differentiation, organogenesis, metamorphosis, the genetic basis of
            development, stem cells.
          </p>
          <p>
            <b> Ecology:</b> The ecosystem, habitats, the food chain, population
            dynamics, species diversity, zoogeography, biogeochemical cycles,
            conservation biology.
          </p>
          <p>
            <b>Animal Behaviour:</b> Types of behaviors, courtship, mating and
            territoriality, instinct, learning and memory, social behavior
            across the animal taxa, communication, pheromones, evolution of
            animal behavior.
          </p>
        </div>
      </div>
    </section>
  );
};

export default GateLifeSciDel;
