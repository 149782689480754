import React from "react";
import AboutIns from "./subcomponent/AboutIns";

const About = () => {
  return (
    <div>
      <AboutIns />
    </div>
  );
};
export default About;
