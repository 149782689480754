import React from "react";
import Logo from "./../image/ONA_LOGO.jpg";
import { GrPhone } from "react-icons/gr";
import { Link } from "react-router-dom";
import "./../style/topheader.css";

const HeaderTop = () => {
  return (
    <>
      <div className="headertop">
        {/* <img src={Logo} alt="" /> */}
        <div className="headertag">
          <h2>Odisha NET Academy-ONA</h2>
        </div>
        <div className="marque">
          <marquee>
            A Complete Solution to CSIR NET / GATE (Life Sciences /
            Biotechnology We are committed to help you to achieve your DREAM.
          
          </marquee>
        </div>
        <div className="contactnumber">
          <p>
            {" "}
            <span>
              <GrPhone color="lightgreen" className="element-to-animate" /> :
            </span>{" "}
            <Link to="tel:+917978171574">91 7978171574</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
