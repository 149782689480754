import React from "react";
import HomeScreenSlider from "./HomeScreenSlider";
import Tableandstudy from "./Tableandstudy";
import DemoClasses from "./DemoClasses";
import RegularFee from "./RegularFee";
import TopRankers from "./../component/TopRankers.jsx";
import VerticalCarousel from "./../component/VerticalCarousal.jsx";
import SuccessStory from "./SuccessStory.jsx";
import FeeStructure from "./FeeStructure.jsx";
import ModalForm from "./../component/ModalForm.jsx";
import Blogging from "./Blogging.jsx";
import OurApproach from "./OurApproach.jsx";

const Home = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <HomeScreenSlider />
      <Tableandstudy />
      <DemoClasses />
      <OurApproach />
      <RegularFee />
      {/* <TopRankers /> */}
      <VerticalCarousel />
      <SuccessStory />
      <FeeStructure />
      
      {/* <Blogging /> */}
    </div>
  );
};

export default Home;
