import React from "react";
import Img1 from "./../../image/syllabus.png";
import Pdf from "./../../image/syllabus.pdf"
import "./../../style/csir.css";
import { Link } from "react-router-dom";

const CsirNetSyllabus = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">

      <h1>CSIR NET LIFE SCIENCES SYLLABUS</h1>
        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <h5>
            Council of Scientific and Industrial Research (CSIR) and University
            Grant Commission (UGC) are holding National Eligibility Test for
            Junior Research Fellowship (JRF) and Lectureship.
          </h5>
          <p>
            The question paper shall be divided into three parts, (A, B & C) as
            per syllabus & scheme of exam <br />
            <b>Part-A:</b> Shall be common to all subjects including Engineering
            Sciences. This part shall contain questions pertaining to General
            Aptitude with emphasis on logical reasoning, graphical analysis,
            analytical and numerical ability, quantitative comparison, series
            formation, puzzles, etc. In Engineering Sciences, Part B shall
            contain questions pertaining to Mathematics & Engineering Aptitude &
            Part C shall contain subject-related multiple-choice questions
            (MCQs), generally covering the topics in the syllabus. And for other
            disciplines: <br />
            <b>Part-B:</b> Shall contain subject-related conventional Multiple
            Choice questions (MCQs), generally covering the topics given in the
            syllabus. <br />
            <b>Part-C:</b> Shall contain higher value questions that may test
            the candidates knowledge of scientific concepts and/or application
            of the scientific concepts. The questions shall be of analytical
            nature where a candidate is expected to apply the scientific
            knowledge to arrive at the solution to the given scientific problem.{" "}
            <br />
            <b>Note:</b> Negative marking for wrong answers, wherever required,
            shall be applicable as per the scheme of Exam <br />
            <b>Syllabus:</b> Click on the Following Link to Download the 13
            Pages Syllabus of <br />
            <Link data-aos="fade-left" href = {Pdf} target = "_blank">CSIR-UGC Life Science Syllabus</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default CsirNetSyllabus;
