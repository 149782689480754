import React, { useEffect, useState } from "react";
import { imageDb } from "../../firebaseConfig";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import "./../../style/awardgallery.css";

import img1 from "./../../image/367.jpg";
import img2 from "./../../image/368.jpg";
import img3 from "./../../image/369.jpg";
import img4 from "./../../image/370.jpg";
import img5 from "./../../image/381.jpg";
import img6 from "./../../image/382.jpg";
import img7 from "./../../image/383.jpg";
import img8 from "./../../image/384.jpg";
import img9 from "./../../image/397.jpg";
import img10 from "./../../image/1.1.jpg";

const HomeSlider = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const ImageArray = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
  ];
  useEffect(() => {
    listAll(ref(imageDb, "awardgallery")).then((imgs) => {
      console.log(imgs);
      imgs.items.forEach((val) => {
        getDownloadURL(val).then((url) => {
          setImageUrls((prevUrls) => {
            if (!prevUrls.includes(url)) {
              return [...prevUrls, url];
            }
            return prevUrls;
          });
        });
      });
    });
  }, []);
  return (
    <div className="awardgallery">
      <h2>Award Gallery</h2>
      <div>
        <div
          id="myCarousel"
          class="carousel slide"
          data-ride="carousel"
          data-interval="2000"
        >
          <div className="carousel-inner">
            {imageUrls
              ? imageUrls.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={imageUrl}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))
              : ImageArray.map((imageUrl, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={imageUrl}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
