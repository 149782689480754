// src/Login.js

import React, { useState } from "react";
import { auth } from "./../firebaseConfig.js";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Assuming you have a 'navigate' object available for navigation
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error.message);
    }
  };

  const containerStyle = {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    margin:"auto",
    marginBottom:"3rem",
    marginTop:"3rem"
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    marginBottom: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

  const buttonStyle = {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  // const buttonHoverStyle = {
  //   backgroundColor: '#45a049',
  // };

  return (
    <div style={containerStyle}>
      <h2>Login</h2>
      <label style={labelStyle}>Email:</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={inputStyle}
      />
      <label style={labelStyle}>Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={inputStyle}
      />
      <button
        onClick={handleLogin}
        style={buttonStyle}
      >
        Login
      </button>
    </div>
  );
};

export default Login;
