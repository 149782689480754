import React from "react";
import './../style/termsandcondition.css';
import { Link } from "react-router-dom";

export default function TermsAndCondition() {
  return (
    <section className="termsection">
        <div className="termsdiv">
      <h1 className="termsheading">WELCOME TO Odisha Net Academy, ORISSA</h1><br />
      <div class="elementor-widget-container">
        <p>
          These terms and conditions outline the rules and regulations for the
          use of Odisha Net Academy’s Website, located at&nbsp;
          <Link to="https://odishanetacademy.in/">https://odishanetacademy.in/</Link>
        </p>
        <p>
          Your use of the Site is governed by the policies, terms, and
          conditions set forth below. Please read the following information
          carefully. By using this Site or donating through the site, you
          indicate your acceptance of, and agreement to be bound by, the terms
          set forth below. If you do not agree to these terms and conditions,
          please do not use this Site. These terms and conditions may be changed
          by Odisha Net Academy in the future. It is your responsibility as a user to
          periodically return to this page to review the terms and conditions
          for amendments. The amended terms shall take effect automatically the
          day they are posted on the site. Your continued use of the web site
          following any amendments will constitute agreement to such amendments.
          These Terms of Service were last revised on July, 2022
        </p>
        <p>
          Your continued usage of the site, shall be in acceptance to the terms
          that may be updated in future with retrospective effect. In case you
          do not agree to any terms your sole remedy is to stop using the
          website.
        </p>
        <h4 className="h4heading">License</h4>
        <p>
          Unless otherwise stated, Odisha Net Academy (International Society for Krishna
          Consciousness) and/or its licensors own the intellectual property
          rights for all material on Odisha Net Academy. All intellectual property
          rights are reserved. You may access this from Odisha Net Academy for your
          own personal use subject to restrictions set in these terms and
          conditions. You must not:
        </p>
        <ul>
          <li>Republish material from Odisha Net Academy</li>
          <li>Sell, rent or sub-license material from Odisha Net Academy</li>
          <li>Reproduce, duplicate or copy material from Odisha Net Academy</li>
          <li>Redistribute content from Odisha Net Academy</li>
        </ul>
        <p>
          This Agreement shall begin on the date hereof. Parts of this website
          offer an opportunity for users to post and exchange opinions and
          information in certain areas of the website. Odisha Net Academy (International
          Society for Krishna Consciousness) does not filter, edit, publish or
          review Comments prior to their presence on the website. Comments do
          not reflect the views and opinions of Odisha Net Academy (International Society
          for Krishna Consciousness), its agents, and/or affiliates. Comments
          reflect the views and opinions of the person who posts their views and
          opinions. To the extent permitted by applicable laws, Odisha Net Academy
          (International Society for Krishna Consciousness) shall not be liable
          for the Comments or for any liability, damages or expenses caused
          and/or suffered as a result of any use of and/or posting of and/or
          appearance of the Comments on this website. Odisha Net Academy (International
          Society for Krishna Consciousness) reserves the right to monitor all
          Comments and to remove any Comments which can be considered
          inappropriate, offensive or causes breach of these Terms and
          Conditions.
        </p>
        <ul>
          <li>You warrant and represent that:</li>
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>
        <p>
          You hereby grant Odisha Net Academy (International Society for Krishna
          Consciousness) a non-exclusive license to use, reproduce, edit and
          authorize others to use, reproduce and edit any of your Comments in
          any and all forms, formats or media.
        </p>
        <h4 className="h4heading">IFrames</h4>
        <p>
          Without prior approval and written permission, you may not create
          frames around our Web Pages that alter in any way the visual
          presentation or appearance of our Website.
        </p>
        <h4 className="h4heading">Content Liability</h4>
        <p>
          We shall not be held responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that
          are rising on your Website. No link(s) should appear on any Website
          that may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>
        <h4 className="h4heading">Your Privacy</h4>
        <p>
          <Link to="https://odishanetacademy.in/privacy-policy">
            Please read Privacy Policy
          </Link>
        </p>
        <h4 className="h4heading">Reservation Of Rights</h4>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>
        <h4 className="h4heading">Removal Of Links From Our Website</h4>
        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly. We do not ensure that the information on this
          website is correct, we do not warrant its completeness or accuracy;
          nor do we promise to ensure that the website remains available or that
          the material on the website is kept up to date.
        </p>
        <h4 className="h4heading">Disclaimer</h4>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>
        <ul>
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>
        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>
        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>{" "}
      </div>
    </div>
    </section>
    
  );
}
