import React from "react";
import { Link } from "react-router-dom";

import Fb from "./../image/fb.png";
import Insta from "./../image/insta.png";
import Tweet from "./../image/twee.png";
import Youtube from "./../image/youtube.png";
import Linkedin from "./../image/linkidin.png";
import "./../style/footer.css";
import { color } from "framer-motion";

import { BiLogoGmail } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { FaBloggerB } from "react-icons/fa6";
import { FaBookOpenReader } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  return (
    <div>
      {/* <!-- Footer --> */}
      <footer class=" text-center " style={{ backgroundColor: "#ffd301" }}>
        {/* <!-- Grid container --> */}
        <div class=" p-4 upperfooter">
          {/* <!-- Section: Social media --> */}

          <div className="footerIcon">
            <ul>
              <li>
                <Link
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="300"
                  to=""
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover="hover"
                  whileTap="tap"
                >
                  <div>
                    <img src={Fb} alt="" />
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="400"
                  to=""
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover="hover"
                  whileTap="tap"
                >
                  <div>
                    <img src={Tweet} alt="" />
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="500"
                  to=""
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover="hover"
                  whileTap="tap"
                >
                  <div>
                    <img src={Insta} alt="" />
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="600"
                  to=""
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover="hover"
                  whileTap="tap"
                >
                  <div>
                    <img src={Linkedin} alt="" />
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="600"
                  to=""
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover="hover"
                  whileTap="tap"
                >
                  <div>
                    <img src={Youtube} alt="" />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- Section: Social media --> */}

          {/* <!-- Section: Text --> */}
          <section class="mb-4">
            <p className="text-justify">
              <span className="ona-text ">Odisha NET Academy (ONA)</span> is the
              pioneer CSIR NET Academy (For Life Sciences) in Bhubaneswar,
              Odisha. It was established in 2011 August 8th. Although the aim of
              our classes is to prepare the students for JRF-NET exam, we also
              provide additional useful information to reinforce their
              foundation so that they are equipped to face any interview or
              other Ph.D. entrance Exams. Thus the coaching is also beneficial
              for GATE, ICMR, DBT and various other entrance examinations.
            </p>
          </section>
          {/* <!-- Section: Text --> */}

          {/* <!-- Section: Links --> */}
          <section class="">
            {/* <!--Grid row--> */}
            <div class="row">
              <div class="aligning col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="textleft text-uppercase">Teach with us</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/get-email-update" class="text-dark">
                     <FaBookOpenReader/> For educators
                    </Link>
                  </li>

                  <li>
                    <Link to="/get-email-update" class="text-dark">
                     <FaBookOpenReader/> Research
                    </Link>
                  </li>

                  <li>
                    <Link to="/get-email-update" class="text-dark">
                    <FaBookOpenReader/>  How it works
                    </Link>
                  </li>
                  <li>
                    <Link to="/get-email-update" class="text-dark">
                     <FaBookOpenReader/> For schools and districts
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="aligning col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="textleft text-uppercase">Learn with us</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/csir-net-coaching" class="text-dark">
                   <FaBookOpenReader/>   CSIR-NET Life Sci.
                    </Link>
                  </li>
                  <li>
                    <Link to="/gate-life-science-syllabus" class="text-dark">
                    <FaBookOpenReader/>  GATE (Life Sciences)
                    </Link>
                  </li>
                  <li>
                    <Link to="/gate-biotechnology-syllabus" class="text-dark">
                    <FaBookOpenReader/>  GATE (Biotechnology)
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="aligning col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="textleft text-uppercase">About</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/calendar" class="text-dark">
                    <FaBloggerB/>  Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/how-to-reach" class="text-dark">
                    <IoCall/>  How To Reach
                    </Link>
                  </li>
                  <li>
                    <Link to="/get-connected-locally" class="text-dark">
                    <IoCall/>  Get Connected
                    </Link>
                  </li>
                  <li>
                    <Link to="/connect-online" class="text-dark">
                    <IoCall/>  Connected online
                    </Link>
                  </li>

                  <li>
                    <Link to="/get-email-update" class="text-dark">
                    <BiLogoGmail/>  Get Email Update
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="aligning  col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 class="textleft text-uppercase">Reach Us</h5>

                <ul class="list-unstyled mb-0">
                  <li>
                    <Link to="/how-to-reach" class="text-dark">
                      <span style={{ color: "green" }}>
                      <IoCall/>  +91 7978171574, 9337727724
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/how-to-reach" class="text-dark">
                      <span style={{ color: "green" }}>
                      <IoLogoWhatsapp/>  +91 7978171574
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/get-connected-locally" class="text-dark">
                      <span style={{ color: "blue" }}>
                        {" "}
                      <BiLogoGmail/>  odishanetacademy@gmail.com{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="textleft">
                    <Link to="/calendar" class="text-dark ">
                     <FaLocationDot/> Sarala Vihar, Nageshwar Road, In front of Gulmohar
                      Apartment, Nandan Vihar, Bhubaneswar, Odisha 751024
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <!--Grid column-->   */}
            </div>
            {/* <!--Grid row--> */}
          </section>
          {/* <!-- Section: Links --> */}
        </div>
        {/* <!-- Grid container --> */}
        {/* <!-- Copyright --> */}
        <div className="footering">
          <div class="text-center p-3" style={{}}>
            <Link class="text-dark" to="/">
              © Copyright 2024 Odisha NET Academy. All Rights Reserved
            </Link>
          </div>
          <div class="text-center p-3" style={{}}>
            <Link class="text-dark" to="/term-and-condition">
              Term & Condition
            </Link>
          </div>
          <div class="text-center p-3" style={{}}>
            <Link class="text-dark" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div class="text-center p-3" style={{}}>
            <Link class="text-dark" to="https://glowtechmor.com/">
              Designed By GlowTechMor Service PVT. LTD
            </Link>
          </div>
        </div>
        {/* <!-- Copyright --> */}
      </footer>
      {/* <!-- Footer --> */}
    </div>
  );
};

export default Footer;
