import React from "react";
import Img1 from "./../../image/pattern.jpg";
import "./../../style/csir.css";
import { Link } from "react-router-dom";


const CsirNetPattern = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">

      <h1>CSIR NET LIFE SCIENCES EXAM PATTERN</h1>
        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <h5>
            TIME: 3 HOURS <br />
            MAXIMUM MARKS: 200
          </h5>
          <p>
            CSIR-UGC (NET) Exam for Award of Junior Research Fellowship and
            Eligibility for Lecturership shall be a Single Paper Test having
            Multiple Choice Questions (MCQs).
          </p>
          <p>
            <b>The question paper is divided into three parts</b>
          </p>
          <p>
            <b>Part - A:</b> This part shall carry 20 questions pertaining to
            General Science, Quantitative Reasoning & Analysis, and Research
            Aptitude. The candidates shall be required to answer any 15
            questions. Each question shall be of two marks. The total marks
            allocated to this section shall be 30 out of 200.
          </p>
          <p>
            <b>Part - B:</b> This part shall contain 50 Multiple Choice
            Questions(MCQs) generally covering the topics given in the syllabus.
            A candidate shall be required to answer any 35 questions. Each
            question shall be of two marks. The total marks allocated to this
            section shall be 70 out of 200.
          </p>
          <p>
            <b>Part - C:</b> This part shall contain 75 questions that are
            designed to test a candidate knowledge of scientific concepts and/or
            application of the scientific concepts. The questions shall be of
            analytical nature where a candidate is expected to apply the
            scientific knowledge to arrive at the solution to the given
            scientific problem. A candidate shall be required to answer any 25
            questions. Each question shall be of four marks. The total marks
            allocated to this section shall be 100 out of 200.
          </p>
          <p>
            <b>
              {" "}
              There will be a negative marking @25% for each wrong answer.{" "}
            </b>
          </p>
          <p>
            To enable the candidates to go through the questions, the question
            paper booklet shall be distributed 15 minutes before the scheduled
            time of the exam. The Answer sheet shall be distributed at the
            scheduled time of the exam.
          </p>
          <p>
            On completion of the exam i.e. at the scheduled closing time of the
            exam, the candidates shall be allowed to carry the Question Paper
            Booklet. No candidate is allowed to carry the Question Paper Booklet
            in case he/she chooses to leave the test before the scheduled
            closing time.
          </p>
          <p>
            <b data-aos="fade-left">
              Visit for on HRDG website{" "}
              <Link to="www.csirhrdg.res.in">www.csirhrdg.res.in</Link> for Model
              Question Paper.
            </b>
          </p>
        </div>
      </div>
    </section>
  );
};

export default CsirNetPattern;
