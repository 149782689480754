import React, { useEffect, useState } from "react";
import "./../style/successStory.css";
import { GrGroup } from "react-icons/gr";
import { FaBookOpen } from "react-icons/fa6";
import { PiStudentFill } from "react-icons/pi";
import { FaRegStar } from "react-icons/fa";
import CountUp from "react-countup";

const SuccessStory = () => {
  const [scrollCount, setScrollCount] = useState(0);

  const handleScroll = () => {
    setScrollCount(window.scrollY);
  };

  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const endValues = [1000, 2500, 5000, 7500, 10000];

  // Find the appropriate end value based on scrollCount
  const currentEndValue = endValues.find(value => scrollCount < value) || endValues.slice(-1)[0];


  return (
    <section className="successstory">
      <h1>SUCCESS STORIES</h1>
      <p>
        We are India's No-1 Coaching Institute for CSIR NET Life Science, GATE
        Biotech, MSc. IIT/JAM Biotechnology & Life Sciences & have over 90%
        Success Ratio (including entire selection) since the inception of the
        academy
      </p>
      <div className="successiconsdiv">
        <div data-aos="flip-right" className="successstorylower">
          <GrGroup />
          <CountUp
            start={0}
            end={currentEndValue}
            style={{ fontSize: "30px", fontWeight: "700" }}
            
            duration={5}
            separator=","
          />
          <p>India's Best Teachers</p>
        </div>
        <div data-aos="flip-right" className="successstorylower">
          <FaBookOpen />
          <CountUp
            start={0}
            end={currentEndValue}
            style={{ fontSize: "30px", fontWeight: "700" }}
            
            duration={5}
            separator=","
          />
          <p>Different Batches</p>
        </div>
        <div data-aos="flip-right" className="successstorylower">
          <PiStudentFill />
          <CountUp
            start={0}
            end={currentEndValue}
            style={{ fontSize: "30px", fontWeight: "700" }}
            
            duration={5}
            separator=","
          />
          <p>Students Per Year</p>
        </div>
        <div data-aos="flip-right" className="successstorylower">
          <FaRegStar />
          <CountUp
            start={0}
            end={currentEndValue}
            style={{ fontSize: "30px", fontWeight: "700" }}
           
            duration={5}
            separator=","
          />
          <p>Yr of Excellence</p>
        </div>
      </div>
    </section>
  );
};

export default SuccessStory;
