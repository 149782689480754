import React from "react";
import Img1 from "./../../image/corres.jpg";
import "./../../style/csir.css";

const GateBioSyllabus = () => {
  return (
    <section className="csirmention">
      <div className="topgate">
        <div className="innergate">
      <h1>GATE BIOTECHNOLOGY SYLLABUS</h1>

        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <p>
            <b>Section 1: Engineering Mathematics</b>
            <br />
          </p>
          <p>
            <b> Linear Algebra:</b> Matrices and determinants, Systems of linear
            equations, Eigenvalues, and Eigenvectors. Calculus: Limit,
            continuity and differentiability, Partial derivatives, Maxima and
            minima, Sequences and series, Test for convergence, Fourier Series.
            Differential.
          </p>

          <p>
            <b>Equations:</b> Linear and nonlinear first-order ODEs,
            higher-order ODEs with constant coefficients equations, Laplace
            transforms, PDE-Laplace, heat, and wave equations. Probability and
            Statistics: Mean, median, mode and standard deviation, Random
            variables, Poisson, normal and binomial distributions, Correlation,
            and regression analysis. Numerical Methods: Solution of linear and
            nonlinear algebraic equations, Integration of trapezoidal and
            Simpson rule, Single and multistep methods for differential
            equations.
          </p>
        </div>
      </div>
      <div className="gatelifesci">
        <img data-aos="fade-left" src={Img1} alt="" />
        <div className="gatelifesciinner">
          <p>
            <b>Section 2: General Biotechnology</b>
          </p>
          <p>
            Biochemistry: Biomolecules-structure and functions; Biological
            membranes, structure, action potential and transport processes;
            Enzymes- classification, kinetics, and mechanism of action; Basic
            concepts and designs of metabolism (carbohydrates, lipids, amino
            acids, and nucleic acids) photosynthesis, respiration, and electron
            transport chain; Bioenergetics
          </p>
          <p>
            <b> Microbiology: </b>Viruses- structure and classification;
            Microbial classification and diversity (bacterial, algal, and
            fungal); Methods in microbiology; Microbial growth and nutrition;
            Aerobic and anaerobic respiration; Nitrogen fixation; Microbial
            diseases and host-pathogen interaction.
          </p>
          <p>
            <b> Cell Biology:</b> Prokaryotic and eukaryotic cell structure;
            Cell cycle and cell growth control; Cell-Cell communication, Cell
            signaling, and signal transduction
          </p>
          <p>
            <b>Molecular Biology and Genetics:</b> Molecular structure of genes
            and chromosomes; Mutations and mutagenesis; Nucleic acid
            replication, transcription, translation, and their regulatory
            mechanisms in prokaryotes and eukaryotes; Mendelian inheritance;
            Gene interaction; Complementation; Linkage, recombination, and
            chromosome mapping; Extra chromosomal inheritance; Microbial
            genetics (plasmids, transformation, transduction, conjugation);
            Horizontal gene transfer and Transposable elements; RNA
            interference; DNA damage and repair; Chromosomal variation;
            Molecular basis of genetic diseases Analytical Techniques:
            Principles of microscopy-light, electron, fluorescent and confocal;
            Centrifugation- high speed and ultra; Principles of spectroscopy-UV,
            visible, CD, IR, FTIR, Raman, MS, NMR; Principles of chromatography-
            ion exchange, gel filtration, hydrophobic interaction, affinity, GC,
            HPLC, FPLC; Electrophoresis; Microarray
          </p>
          <p>
            <b>Immunology:</b> History of Immunology; Innate, humoral and
            cell-mediated immunity; Antigen; Antibody structure and function;
            Molecular basis of antibody diversity; Synthesis of antibody and
            secretion; Antigen-antibody reaction; Complement; Primary and
            secondary lymphoid organ; B and T cells and macrophages; Major
            histocompatibility complex (MHC); Antigen processing and
            presentation; Polyclonal and monoclonal antibody; Regulation of
            immune response; Immune tolerance; Hypersensitivity; Autoimmunity;
            Graft versus host reaction.
          </p>
          <p>
            <b>Bioinformatics:</b> Major bioinformatic resources and search
            tools; Sequence and structure databases; Sequence analysis
            (biomolecular sequence file formats, scoring matrices, sequence
            alignment, phylogeny); Data mining and analytical tools for genomic
            and proteomic studies; Molecular dynamics and simulations (basic
            concepts including force fields, protein-protein, protein-nucleic
            acid, protein-ligand interaction)
          </p>
        </div>
      </div>
      <div className="gatelifesci1">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="gatelifesciinner1">
          <p>
            <b>Section 3: Recombinant DNA Technology</b>
          </p>
          <p>
            Restriction and modification enzymes; Vectors; plasmid,
            bacteriophage, and other viral vectors, cosmids, Ti plasmid, yeast
            artificial chromosome; mammalian and plant expression vectors; cDNA
            and genomic DNA library; Gene isolation, cloning, and expression;
            Transposons and gene targeting; DNA labeling; DNA sequencing;
            Polymerase chain reactions; DNA fingerprinting; Southern and
            northern blotting; In-situ hybridization; RAPD, RFLP; Site-directed
            mutagenesis; Gene transfer technologies; Gene therapy
          </p>
        </div>
      </div>
      <div className="gatelifesci">
        <img data-aos="fade-left" src={Img1} alt="" />
        <div className="gatelifesciinner">
          <p>
            <b>Section 4: Plant and Animal Biotechnology</b>
            Totipotency; Regeneration of plants; Plant growth regulators and
            elicitors; Tissue culture and Cell suspension culture system:
            methodology, the kinetics of growth and, nutrient optimization;
            Production of secondary metabolites by plant suspension cultures;
            Hairy root culture; transgenic plants; Plant products of industrial
            importance Animal cell culture; media composition and growth
            conditions; Animal cell and tissue preservation; Anchorage and
            non-anchorage dependent cell culture; Kinetics of cell growth; Micro
            & macro-carrier culture; Hybridoma technology; Stem cell technology;
            Animal cloning; Transgenic animals
          </p>
        </div>
      </div>
      <div className="gatelifesci1">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="gatelifesciinner1">
          <p>
            <b>Section 5: Bioprocess Engineering and Process Biotechnology</b>
          </p>
          <p>
            Chemical engineering principles applied to biological system,
            Principle of reactor design, ideal and non-ideal multiphase
            bioreactors, mass and heat transfer; Rheology of fermentation
            fluids, Aeration and agitation; Media formulation and optimization;
            Kinetics of microbial growth, substrate utilization and product
            formation; Sterilization of air and media; Batch, fed-batch and
            continuous processes; Various types of microbial and enzyme
            reactors; Instrumentation control and optimization; Unit operations
            in solid-liquid separation and liquid-liquid extraction; Process
            scale-up, economics and feasibility analysis Engineering principle
            of bioprocessing- Upstream production and downstream; Bioprocess
            design and development from lab to industrial scale; Microbial,
            animal and plant cell culture platforms; Production of biomass and
            primary/secondary metabolites; Biofuels, Bioplastics, industrial
            enzymes, antibiotics; Large scale production and purification of
            recombinant proteins; Industrial application of chromatographic and
            membrane-based bioseparation methods; Immobilization of biocatalysts
            (enzymes and cells) for bioconversion processes;
            Bioremediation-Aerobic and anaerobic processes for stabilization of
            solid / liquid wastes.
          </p>
        </div>
      </div>
    </section>
  );
};

export default GateBioSyllabus;
