import React from "react";
import HomeTable from "./subcomponent/HomeTable";
import HomeSlider from "./subcomponent/HomeSlider";
import StudyMaterial from "./subcomponent/StudyMaterial";
import "./../style/studyandtable.css";
const Tableandstudy = () => {
  return (
    <div className="studyandtable">
      <HomeTable />
      <HomeSlider />
      <StudyMaterial />
    </div>
  );
};

export default Tableandstudy;
