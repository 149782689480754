import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
// import InputGroup from "react-bootstrap/InputGroup";
import "./../style/modalform.css";

import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";
import axios from "axios";

const ModalForm = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const jumpVariants = {
    initial: { y: 0 },
    jump: {
      y: [0, -10, 0],
      transition: {
        duration: 0.5,
        repeat: Infinity,
      },
    },
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mscin: "",
    phonenumber: "",
    interest: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post("https://ona-backend.onrender.com/submitForm", formData);
      // await axios.post('http://localhost:5500/submitForm', formData);
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    } finally {
      // Hide the loader after the form submission is complete
      setLoading(false);
      setFormData({
        name: "",
        email: "",
        mscin: "",
        phonenumber: "",
        interest: "",
        message: "",
      });
    }
  };

  return (
    <>
      <Box
        className="contributeButton"
        sx={{ "& > :not(style)": { m: 1 } }}
        style={{
          position: "fixed",
          bottom: "152px",
          right: "5px",
          display: "flex",
          zIndex: "2",
        }}
      >
        <motion.div
          initial="initial"
          animate="jump"
          variants={jumpVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Fab style={{ backgroundColor: "green" }} aria-label="add">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/917978171574"
              style={{ textDecoration: "none", color: "white" }}
            >
              <FaWhatsapp style={{ fontSize: "30px", color: "white" }} />
            </a>
          </Fab>
        </motion.div>
        <Fab
          style={{
            backgroundColor: "orange",
            fontSize: "15px",
            color: "white",
          }}
          variant="extended"
          onClick={handleShow}
          className="flot-btn"
        >
          Get Free Callback
        </Fab>
      </Box>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Get Free Callback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Student Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMscIn">
                <Form.Label>Msc In</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Msc In"
                  name="mscin"
                  value={formData.mscin}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder="Enter your phone No."
                name="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridInterest">
              <Form.Label>Interested For</Form.Label>
              <Form.Control
                placeholder="Interested For ?"
                name="interest"
                value={formData.interest}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGridInterest">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                aria-label="With textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Form.Group>

            <Button className="btncolor" variant="" type="submit">
              Submit
            </Button>
            {loading && <div className="loader"></div>}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalForm;
