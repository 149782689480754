import React from "react";
import "./../style/connectusonline.css";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoMdMailOpen } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { GiBlackBook } from "react-icons/gi";
import { IoPeopleSharp } from "react-icons/io5";
import { TiVendorAndroid } from "react-icons/ti";

const ConnectUsOnlineColumn = () => {
  return (
    <section className="connectusonlineSection">
      <div
        className="connectusonlineHeading"
        data-aos="fade-down"
        data-aos-easing="ease-in-sine"
        data-aos-delay="300"
      >
        <h1>CONNECT WITH US ONLINE</h1>
      </div>
      <div className="connectusonlinerow">
        <div className="connectusonlinecolumn">
          <div className="cardStyles">
            <h3>Join our WhatsApp Community</h3>
            <div className="buttonContainerStyles">
              <button className="buttonStyles">
                <IoPeopleSharp color="white"/> LECTURES
              </button>
              <button className="buttonStyles">
                <IoPeopleSharp color="white" /> EVENTS & UPDATES
              </button>
            </div>
          </div>
        </div>

        <div className="connectusonlinecolumn">
          <div className="cardStyles">
            <h3>Career</h3>
            <div className="buttonContainerStyles">
              <button className="buttonStyles">
                <GiBlackBook color="white" /> E-COURSES
              </button>
              <button className="buttonStyles">
                <GiBlackBook color="white"/> E-LEARNING
              </button>
            </div>
          </div>
        </div>

        <div className="connectusonlinecolumn">
          <div className="cardStyles">
            <h3>Apps</h3>
            <div className="buttonContainerStyles">
              <button className="buttonStyles">
                <BiWorld color="white"/> MIGHTY NETWORK WEBSITE
              </button>
              <button className="buttonStyles">
                <TiVendorAndroid color="white"/> MIGHTY NETWORK (ANDROID)
              </button>
              <button className="buttonStyles">
                <TiVendorAndroid color="white"/> MIGHTY NETWORK (IOS)
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="connectusonlinerow">
        <div className="connectusonlinecolumn">
          <div className="cardStyles">
            <h3>ONA</h3>
            <div className="buttonContainerStyles">
              <button className="buttonStyles">
                <FaFacebook color="blue"/> FACEBOOK
              </button>
              <button className="buttonStyles">
                <FaInstagram color="pink"/> INSTAGRAM
              </button>
              <button className="buttonStyles">
                <FaTwitter color="skyblue"/> TWITTER
              </button>
              <button className="buttonStyles">
                <FaYoutube /> YOUTUBE
              </button>
              <button className="buttonStyles">
                <FaTelegram color="lightskyblue"/> TELEGRAM
              </button>
             
              <button className="buttonStyles">
                <IoMdMailOpen color="white"/> GET UPDATES
              </button>
              <button className="buttonStyles">
                <FaWhatsapp color="lightgreen" /> WHATSAPP COMMUNITY
              </button>
            </div>
          </div>
        </div>

       
      </div>

      
    </section>
  );
};

export default ConnectUsOnlineColumn;
