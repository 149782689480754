import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./component/Footer.jsx";
import Header from "./component/Header.jsx";

import HomeScreenSlider from "./component/HomeScreenSlider.jsx";
import ContactUs from "./component/ContactUs.jsx";
import HeaderTop from "./component/HeaderTop.jsx";
import Tableandstudy from "./component/Tableandstudy.jsx";
import DemoClasses from "./component/DemoClasses.jsx";
import RegularFee from "./component/RegularFee.jsx";
import TopRankers from "./component/TopRankers.jsx";
import VerticalCarousel from "./component/VerticalCarousal.jsx";
import FeeStructure from "./component/FeeStructure.jsx";
import SuccessStory from "./component/SuccessStory.jsx";
import Blogging from "./component/Blogging.jsx";
import About from "./component/About.jsx";
import ModalForm from "./component/ModalForm.jsx";
import Gallery from "./component/Gallery.jsx";
import Director from "./component/subcomponent/Director.jsx";
import Home from "./component/Home.jsx";
import CsirNetCoach from "./component/csirnet/CsirNetCoach.jsx";
import CsirNetSyllabus from "./component/csirnet/CsirNetSyllabus.jsx";
import CsirNetPattern from "./component/csirnet/CsirNetPattern.jsx";
import CsirNetClassroom from "./component/csirnet/CsirNetClassroom.jsx";
import CsirNetCorres from "./component/csirnet/CsirNetCorres.jsx";
import CsirNetSci from "./component/csirnet/CsirNetSci.jsx";
import GateLifeSciDel from "./component/gate/GateLifeSciDel.jsx";
import GateLifeSciPat from "./component/gate/GateLifeSciPat.jsx";
import GateLifeSciClass from "./component/gate/GateLifeSciClass.jsx";
import GateLifeSciCorres from "./component/gate/GateLifeSciCorres.jsx";
import GateLifeSciCoaching from "./component/gate/GateLifeSciCoaching.jsx";
import GateBioSyllabus from "./component/gate/GateBioSyllabus.jsx";
import GateBioPattern from "./component/gate/GateBioPattern.jsx";
import GateBioClassroom from "./component/gate/GateBioClassroom.jsx";
import GateBioCorres from "./component/gate/GateBioCorres.jsx";
import GateBioCoaching from "./component/gate/GateBioCoaching.jsx";
import Urgent from "./component/Urgent.jsx";
import GetEmailUpdate from "./component/GetEmailUpdate.jsx";
import Calendar from "./component/VaishnavaCalender.jsx";
import HowToReach from "./component/HowToReach.jsx";
import ConnectOnline from "./component/ConnectUsOnline.jsx";
import Blog from "./component/Blog.jsx";
import PrivacyPolicy from "./component/Privacy-policy.jsx";
import TermCondition from "./component/TermsAndCondition.jsx";
import OurApproach from "./component/OurApproach.jsx";
import GalleryImageUpload from "./component/allImageUpload/GalleryImageUpload.jsx";
import HomeScreenSliderUpload from "./component/allImageUpload/HomeScreenSliderUpload.jsx";
import AwardGalleryUpload from "./component/allImageUpload/AwardGalleryUpload.jsx";
import StudyMaterialUpload from "./component/allImageUpload/StudyMaterialUpload.jsx";
import PrivateRoute from "./component/PrivateRoute.jsx";
import Dashboard from "./component/Dashboard.jsx";
import Login from "./component/Login.jsx";
// import ContactUs from "./component/ContactUs.jsx"
function App() {
  return (
    <>
      <BrowserRouter>
        <HeaderTop />
        <Header />

        {/* <Urgent/> */}
        <Routes>
          <Route index element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute />} />
          {/* <PrivateRoute path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route
            path="/studymaterialupload"
            element={<StudyMaterialUpload />}
          />
          <Route path="/awardgalleryupload" element={<AwardGalleryUpload />} />
          <Route path="/homeslider" element={<HomeScreenSliderUpload />} />
          <Route path="/galleryupload" element={<GalleryImageUpload />} />
          <Route path="/blog" element={<Blog />} />
          {/* <Route path="/ourapproach" element={} /> */}
          <Route path="/get-connected-locally" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-and-condition" element={<TermCondition />} />
          <Route path="/connect-online" element={<ConnectOnline />} />
          <Route path="/how-to-reach" element={<HowToReach />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/get-email-update" element={<GetEmailUpdate />} />
          <Route path="/director-desk" element={<Director />} />
          <Route path="/about-institute" element={<About />} />
          <Route path="/csir-net-coaching" element={<CsirNetCoach />} />
          <Route path="/csir-net-syllabus" element={<CsirNetSyllabus />} />
          <Route path="/csir-net-pattern" element={<CsirNetPattern />} />
          <Route path="/csir-net-classroom" element={<CsirNetClassroom />} />
          <Route path="/csir-net-corresponding" element={<CsirNetCorres />} />
          <Route path="/csir-net-science" element={<CsirNetSci />} />
          {/* <Route path="/gate-life-science-syllabus" element={<GateLifeSciDel />} /> */}
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/gate-life-science-pattern"
            element={<GateLifeSciPat />}
          />
          <Route
            path="/gate-life-science-classroom"
            element={<GateLifeSciClass />}
          />
          <Route
            path="/gate-life-science-correspondence"
            element={<GateLifeSciCorres />}
          />
          <Route
            path="/gate-life-science-syllabus"
            element={<GateLifeSciDel />}
          />
          <Route
            path="/gate-life-science-coaching"
            element={<GateLifeSciCoaching />}
          />
          <Route
            path="/gate-biotechnology-syllabus"
            element={<GateBioSyllabus />}
          />
          <Route
            path="/gate-biotechnology-pattern"
            element={<GateBioPattern />}
          />
          <Route
            path="/gate-biotechnology-classroom"
            element={<GateBioClassroom />}
          />
          <Route
            path="/gate-biotechnology-correspondence"
            element={<GateBioCorres />}
          />
          <Route
            path="/gate-biotechnology-coaching"
            element={<GateBioCoaching />}
          />
        </Routes>
        <ModalForm />
        {/* <Login/> */}
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default App;
