// src/Dashboard.js

import React from "react";

const Dashboard = () => {
  const containerStyle = {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    marginTop:"3rem",
    marginBottom:"3rem"
  };

  const headingStyle = {
    color: '#333',
  };

  const linkStyle = {
    display: 'block',
    marginTop: '20px',
    color: '#007bff',
    textDecoration: 'none',
  };

  const linkHoverStyle = {
    textDecoration: 'underline',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Dashboard</h2>
      <a
        href="https://console.firebase.google.com/u/2/project/odishanetacademynew/firestore/data/~2FcontactusForm~2FhkQFGOkd3Dt2SLzj8rIT"
        style={linkStyle}
        onMouseOver={(e) => (e.target.style = linkHoverStyle)}
        onMouseOut={(e) => (e.target.style = linkStyle)}
      >
        Go to Another Page
      </a>
    </div>
  );
};

export default Dashboard;
