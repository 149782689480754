import React from "react";
import Img1 from "./../../image/corres.jpg";
import "./../../style/csir.css";

const GateLifeSciPat = () => {
  return (
    <section className="csirmention">
      <div className="topcsir">
        <div className="innercsir">
      <h1>GATE LIFE SCIENCES EXAM PATTERN</h1>

        </div>
      </div>
      <div className="inforcsir">
        <img data-aos="fade-right" src={Img1} alt="" />
        <div className="csirnetpara">
          <p>
            The GATE 2016 Examination consists of a single paper of 3-hour
            duration that contains 65 questions carrying a maximum of 100 marks.
            The question paper will consist of both multiple-choice questions (
            MCQ ) and numerical answer-type questions.
          </p>
          <p>
            GATE 2016 contain questions of two different types in various
            papers:
          </p>
          <p>
            <b>(i) Multiple Choice Questions (MCQ)</b> carrying 1 or 2 marks
            each in all papers and sections. These questions are objective in
            nature, and each will have a choice of four answers, out of which
            the candidate has to mark the correct answer(s).
          </p>
          <p>
            <b>(ii) Numerical Answer Questions</b> of 1 or 2 marks each in all
            papers and sections. For these questions the answer is a real
            number, to be entered by the candidate using the virtual keypad. No
            choices will be shown for this type of question.
          </p>
          <p>
            <b>Exam Duration:</b> 3 hours{" "}
          </p>
          <p>
            <b>Exam Type:</b> ONLINE Computer Based Test ( CBT ){" "}
          </p>
          <p>
            At the end of the 3-hour window, the computer will automatically
            close the screen from further actions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default GateLifeSciPat;
