// // VerticalCarousel.js
// import React, { useState, useEffect } from 'react';
// import './../../style/verticalcar.css';

// const VerticalCarousel = ({ links }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isPaused, setIsPaused] = useState(false);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       if (!isPaused) {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % links.length);
//       }
//     }, 3000); // Adjust the interval as needed

//     return () => clearInterval(intervalId);
//   }, [currentIndex, isPaused, links.length]);

//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + links.length) % links.length);
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % links.length);
//   };

//   const handleMouseEnter = () => {
//     setIsPaused(true);
//   };

//   const handleMouseLeave = () => {
//     setIsPaused(false);
//   };

//   return (
//     <div
//       className="vertical-carousel1"
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//     >
//       <button className="control-button1 prev1" onClick={handlePrev}>
//         &lt;
//       </button>
//       <div className="carousel-container1">
//         <div className="carousel-inner-container">
//           {links.map((link, index) => (
//             <Link
//               key={index}
//               to={link.url}
//               className={`carousel-item1 ${index >= currentIndex && index < currentIndex + 3 ? 'active' : ''}`}
//             >
//               {link.label}
//             </Link>
//           ))}
//         </div>
//       </div>
//       <button className="control-button1 next1" onClick={handleNext}>
//         &gt;
//       </button>
//     </div>
//   );
// };

// export default VerticalCarousel;

import React from "react";
import "./../style/verticalcar.css";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import Jakesh from "./../image/Jakesh.jpg";
import Bikash from "./../image/Bikash.jpg";
import Parmita from "./../image/parmita.jpg";
import Sumanta from "./../image/Sumanta.jpg";
import Sushree from "./../image/Sushree Sangita.jpg";

import New from "./../image/new.jpg";
const VerticalCarousal = () => {
  return (
    <>
      <div className="verticalcarousal">
        {/* <Marquee
          speed={60}
          delay={0}
          gradientWidth={200}
          gradient={true}
          pauseOnHover={true}
        >
          <ul className="ulvertical">
            <li>
              <Link to="">
                <img src={New} alt="" /> NET JRF Answer Key
              </Link>
            </li>
            <li>
              <Link to="">
                <img src={New} alt="" /> GATE Answer Key
              </Link>
            </li>
            <li>
              <Link to="">
                <img src={New} alt="" /> IIT JAM Answer Key
              </Link>
            </li>
            <li>
              <Link to="">
                <img src={New} alt="" /> M.Sc Entrance Answer Key
              </Link>
            </li>
          </ul>
        </Marquee> */}
      </div>
      <div className="carousaling">
        <div className="studymaterial1">
          <h2>What Our Student's Say</h2>
          <div
            id="carouselExampleAutoplaying"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div className="card25">
                  <div className="card-content25">
                    <div className="image-container25">
                      <img src={Jakesh} alt="card25 Image" />
                    </div>

                    <div className="text-container25">
                      <div className="card25-footer25">
                        <span className="footer-text25">
                          JAKESH MOHAPATRA, CSIR JRF 66, PhD, ISRO, Bangalore
                        </span>
                      </div>
                      <h6>
                        ONA is a very good institute. Arun Sir explains the
                        important concepts in a very simple way. He is very
                        friendly and supportive. There is regular examination
                        and discussion which best for every aspiring students
                        for qualifying net. I recommend ONA to every biology
                        students.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="card25">
                  <div className="card-content25">
                    <div className="image-container25">
                      <img src={Bikash} alt="card25 Image" />
                    </div>
                    <div className="text-container25">
                      <div className="card25-footer25">
                        <span className="footer-text25">
                          Bikash Chandra Jena, CSIR NET JRF-30, PhD, IIT
                          Kharagpur
                        </span>
                      </div>
                      <h6>
                        One of the Premier Institute of Odisha for CSIR NET
                        preparation. The friendly atmosphere you get in ONA is
                        very helpful for your progression. The most important is
                        the teaching style of Arun Sir which is very needful for
                        strong foundation. Lastly strong self motivation is most
                        important to crack such prestigious exam to enter
                        research career.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="card25">
                  <div className="card-content25">
                    <div className="image-container25">
                      <img src={Parmita} alt="card25 Image" />
                    </div>
                    <div className="text-container25">
                      <div className="card-footer25">
                        <span className="footer-text25">
                          Parmita Chawley, JRF-71, PhD, IIT(ISM) DHANBAD
                        </span>
                      </div>
                      <h6>
                        Here are the few lines ,I want to share...&quot;Going to
                        ONA classes kept me on track with studies and Learned
                        new concepts ..I found ONA&#39;s sunday test very
                        effective&quot;
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="card25">
                  <div className="card-content25">
                    <div className="image-container25">
                      <img src={Sumanta} alt="card25 Image" />
                    </div>
                    <div className="text-container25">
                      <div className="card-footer25">
                        <span className="footer-text25">
                          Sumanta Kumar Naik, CSIR JRF- JRF-101, Hamburg,
                          Germany
                        </span>
                      </div>
                      <h6>
                        ONA helped me a lot to prepare myself for the national
                        level examination in a very precise and planned way. For
                        the competitive examinations, you need proper guidance
                        and strategies which I got from Arun Sir and ONA
                        Academy.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="card25">
                  <div className="card-content25">
                    <div className="image-container25">
                      <img src={Sushree} alt="card25 Image" />
                    </div>
                    <div className="text-container25">
                      <div className="card-footer25">
                    <span className="footer-text25">
                      Sushree sangita Priyadarshini, CSIR JRF 53, PhD, South
                      Asian University, Delhi
                    </span>
                  </div>
                      <h6>
                        Hardwork , Punctuality and Sincerity....three words are
                        mantras for getting success in any exam, these are not
                        my words. I have learnt these from my teacher Arun Kumar
                        Pradhan. I am remembering the time when I joined Odisha
                        NET Academy, I was nothing at that time, but the way ,
                        Arun sir was teaching in the classroom and his highly
                        motivational word was the main stimulator for me to
                        understand most of the concept very easily, Truly I am
                        dedicating my success (CSIR JRF, ICMR JRF) to him. Just
                        consider what he is saying, and very soon you will
                        realize the truth of his words..Really feeling proud for
                        coming in contact with Arun Sir.
                      </h6>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerticalCarousal;
